.bg-gradient-white
{
    color: #fff;
    background-image: -webkit-linear-gradient(60deg, #fff, rgba(255, 255, 255, .5));
    background-image:    -moz-linear-gradient(60deg, #fff, rgba(255, 255, 255, .5));
    background-image:      -o-linear-gradient(60deg, #fff, rgba(255, 255, 255, .5));
    background-image:         linear-gradient(30deg, #fff, rgba(255, 255, 255, .5));
    background-repeat: repeat-x;
}
.bg-gradient-white:hover,
.bg-gradient-white:active
{
    color: #fff;
}

.gradient-light-white
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #fff 0%, 100%);
    background:    -moz-linear-gradient(60deg, #fff 0%, 100%);
    background:      -o-linear-gradient(60deg, #fff 0%, 100%);
    background:         linear-gradient(30deg, #fff 0%, 100%);
}
.gradient-light-white:hover,
.gradient-light-white:active
{
    color: #fff;
}

.bg-gradient-black
{
    color: #fff;
    background-image: -webkit-linear-gradient(60deg, #000, rgba(0, 0, 0, .5));
    background-image:    -moz-linear-gradient(60deg, #000, rgba(0, 0, 0, .5));
    background-image:      -o-linear-gradient(60deg, #000, rgba(0, 0, 0, .5));
    background-image:         linear-gradient(30deg, #000, rgba(0, 0, 0, .5));
    background-repeat: repeat-x;
}
.bg-gradient-black:hover,
.bg-gradient-black:active
{
    color: #fff;
}

.gradient-light-black
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #000 0%, 100%);
    background:    -moz-linear-gradient(60deg, #000 0%, 100%);
    background:      -o-linear-gradient(60deg, #000 0%, 100%);
    background:         linear-gradient(30deg, #000 0%, 100%);
}
.gradient-light-black:hover,
.gradient-light-black:active
{
    color: #fff;
}

.bg-gradient-dark
{
    color: #fff;
    background: #640064;
    background-image: -webkit-linear-gradient(60deg, #1e1e1e, rgba(30, 30, 30, .5));
    background-image:    -moz-linear-gradient(60deg, #1e1e1e, rgba(30, 30, 30, .5));
    background-image:      -o-linear-gradient(60deg, #1e1e1e, rgba(30, 30, 30, .5));
    background-image:         linear-gradient(30deg, #1e1e1e, rgba(30, 30, 30, .5));
    background-repeat: repeat-x;
}
.bg-gradient-dark:hover,
.bg-gradient-dark:active
{
    color: #fff;
}

.gradient-light-dark
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #1e1e1e 0%, 100%);
    background:    -moz-linear-gradient(60deg, #1e1e1e 0%, 100%);
    background:      -o-linear-gradient(60deg, #1e1e1e 0%, 100%);
    background:         linear-gradient(30deg, #1e1e1e 0%, 100%);
}
.gradient-light-dark:hover,
.gradient-light-dark:active
{
    color: #fff;
}

.bg-gradient-light
{
    color: #fff;
    background: #640064;
    background-image: -webkit-linear-gradient(60deg, #babfc7, rgba(186, 191, 199, .5));
    background-image:    -moz-linear-gradient(60deg, #babfc7, rgba(186, 191, 199, .5));
    background-image:      -o-linear-gradient(60deg, #babfc7, rgba(186, 191, 199, .5));
    background-image:         linear-gradient(30deg, #babfc7, rgba(186, 191, 199, .5));
    background-repeat: repeat-x;
}
.bg-gradient-light:hover,
.bg-gradient-light:active
{
    color: #fff;
}

.gradient-light-light
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #babfc7 0%, 100%);
    background:    -moz-linear-gradient(60deg, #babfc7 0%, 100%);
    background:      -o-linear-gradient(60deg, #babfc7 0%, 100%);
    background:         linear-gradient(30deg, #babfc7 0%, 100%);
}
.gradient-light-light:hover,
.gradient-light-light:active
{
    color: #fff;
}

.bg-gradient-primary
{
    color: #fff;
    background: #640064;
    background-image: -webkit-linear-gradient(60deg, #46003A, rgba(115, 103, 240, .5));
    background-image:    -moz-linear-gradient(60deg, #46003A, rgba(115, 103, 240, .5));
    background-image:      -o-linear-gradient(60deg, #46003A, rgba(115, 103, 240, .5));
    background-image:         linear-gradient(30deg, #46003A, rgba(115, 103, 240, .5));
    background-repeat: repeat-x;
}
.bg-gradient-primary:hover,
.bg-gradient-primary:active
{
    color: #fff;
}

.gradient-light-primary
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #46003A 0%, #ce9ffc 100%);
    background:    -moz-linear-gradient(60deg, #46003A 0%, #ce9ffc 100%);
    background:      -o-linear-gradient(60deg, #46003A 0%, #ce9ffc 100%);
    background:         linear-gradient(30deg, #46003A 0%, #ce9ffc 100%);
}
.gradient-light-primary:hover,
.gradient-light-primary:active
{
    color: #fff;
}

.bg-gradient-secondary
{
    color: #fff;
    background: #640064;
    background-image: -webkit-linear-gradient(60deg, #b8c2cc, rgba(184, 194, 204, .5));
    background-image:    -moz-linear-gradient(60deg, #b8c2cc, rgba(184, 194, 204, .5));
    background-image:      -o-linear-gradient(60deg, #b8c2cc, rgba(184, 194, 204, .5));
    background-image:         linear-gradient(30deg, #b8c2cc, rgba(184, 194, 204, .5));
    background-repeat: repeat-x;
}
.bg-gradient-secondary:hover,
.bg-gradient-secondary:active
{
    color: #fff;
}

.gradient-light-secondary
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #b8c2cc 0%, #dcdeec 100%);
    background:    -moz-linear-gradient(60deg, #b8c2cc 0%, #dcdeec 100%);
    background:      -o-linear-gradient(60deg, #b8c2cc 0%, #dcdeec 100%);
    background:         linear-gradient(30deg, #b8c2cc 0%, #dcdeec 100%);
}
.gradient-light-secondary:hover,
.gradient-light-secondary:active
{
    color: #fff;
}

.bg-gradient-success
{
    color: #fff;
    background: #0064fa;
    background-image: -webkit-linear-gradient(60deg, #28c76f, rgba(40, 199, 111, .5));
    background-image:    -moz-linear-gradient(60deg, #28c76f, rgba(40, 199, 111, .5));
    background-image:      -o-linear-gradient(60deg, #28c76f, rgba(40, 199, 111, .5));
    background-image:         linear-gradient(30deg, #28c76f, rgba(40, 199, 111, .5));
    background-repeat: repeat-x;
}
.bg-gradient-success:hover,
.bg-gradient-success:active
{
    color: #fff;
}

.gradient-light-success
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #28c76f 0%, #4ae9d3 100%);
    background:    -moz-linear-gradient(60deg, #28c76f 0%, #4ae9d3 100%);
    background:      -o-linear-gradient(60deg, #28c76f 0%, #4ae9d3 100%);
    background:         linear-gradient(30deg, #28c76f 0%, #4ae9d3 100%);
}
.gradient-light-success:hover,
.gradient-light-success:active
{
    color: #fff;
}

.bg-gradient-info
{
    color: #fff;
    background: #640064;
    background-image: -webkit-linear-gradient(60deg, #00cfe8, rgba(0, 207, 232, .5));
    background-image:    -moz-linear-gradient(60deg, #00cfe8, rgba(0, 207, 232, .5));
    background-image:      -o-linear-gradient(60deg, #00cfe8, rgba(0, 207, 232, .5));
    background-image:         linear-gradient(30deg, #00cfe8, rgba(0, 207, 232, .5));
    background-repeat: repeat-x;
}
.bg-gradient-info:hover,
.bg-gradient-info:active
{
    color: #fff;
}

.gradient-light-info
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #00cfe8 0%, #2d91ff 100%);
    background:    -moz-linear-gradient(60deg, #00cfe8 0%, #2d91ff 100%);
    background:      -o-linear-gradient(60deg, #00cfe8 0%, #2d91ff 100%);
    background:         linear-gradient(30deg, #00cfe8 0%, #2d91ff 100%);
}
.gradient-light-info:hover,
.gradient-light-info:active
{
    color: #fff;
}

.bg-gradient-warning
{
    color: #fff;
    background: #fafafa;
    background-image: -webkit-linear-gradient(60deg, #ff9f43, rgba(255, 159, 67, .5));
    background-image:    -moz-linear-gradient(60deg, #ff9f43, rgba(255, 159, 67, .5));
    background-image:      -o-linear-gradient(60deg, #ff9f43, rgba(255, 159, 67, .5));
    background-image:         linear-gradient(30deg, #ff9f43, rgba(255, 159, 67, .5));
    background-repeat: repeat-x;
}
.bg-gradient-warning:hover,
.bg-gradient-warning:active
{
    color: #fff;
}

.gradient-light-warning
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #ff9f43 0%, #fff487 100%);
    background:    -moz-linear-gradient(60deg, #ff9f43 0%, #fff487 100%);
    background:      -o-linear-gradient(60deg, #ff9f43 0%, #fff487 100%);
    background:         linear-gradient(30deg, #ff9f43 0%, #fff487 100%);
}
.gradient-light-warning:hover,
.gradient-light-warning:active
{
    color: #fff;
}

.bg-gradient-danger
{
    color: #fff;
    background: #640064;
    background-image: -webkit-linear-gradient(60deg, #ea5455, rgba(234, 84, 85, .5));
    background-image:    -moz-linear-gradient(60deg, #ea5455, rgba(234, 84, 85, .5));
    background-image:      -o-linear-gradient(60deg, #ea5455, rgba(234, 84, 85, .5));
    background-image:         linear-gradient(30deg, #ea5455, rgba(234, 84, 85, .5));
    background-repeat: repeat-x;
}
.bg-gradient-danger:hover,
.bg-gradient-danger:active
{
    color: #fff;
}

.gradient-light-danger
{
    color: #fff; 
    background: -webkit-linear-gradient(60deg, #ea5455 0%, #f9b789 100%);
    background:    -moz-linear-gradient(60deg, #ea5455 0%, #f9b789 100%);
    background:      -o-linear-gradient(60deg, #ea5455 0%, #f9b789 100%);
    background:         linear-gradient(30deg, #ea5455 0%, #f9b789 100%);
}
.gradient-light-danger:hover,
.gradient-light-danger:active
{
    color: #fff;
}
