/*========================================================
        DARK LAYOUT
=========================================================*/
.data-list-view-header .table-responsive .top,
.data-thumb-view-header .table-responsive .top
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-bottom: 1rem; 
    padding: 0 1rem;

    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.data-list-view-header .table-responsive .top .action-btns,
.data-thumb-view-header .table-responsive .top .action-btns
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex; 

    margin-top: 1.5rem;
}
.data-list-view-header .table-responsive .top .action-btns .actions-dropodown,
.data-thumb-view-header .table-responsive .top .action-btns .actions-dropodown
{
    margin-right: .75rem; 

    border-radius: .5rem;
    -webkit-box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
            box-shadow: 0 2px 8px 0 rgba(0, 0, 0, .14);
}
.data-list-view-header .table-responsive .top .action-btns .actions-dropodown .dropdown-toggle,
.data-thumb-view-header .table-responsive .top .action-btns .actions-dropodown .dropdown-toggle
{
    font-size: 1.125rem;
    font-weight: 500;
}
.data-list-view-header .table-responsive .top .action-btns .actions-dropodown .dropdown-toggle:after,
.data-thumb-view-header .table-responsive .top .action-btns .actions-dropodown .dropdown-toggle:after
{
    left: 0;
}
.data-list-view-header .table-responsive .top .action-btns .dt-buttons .btn,
.data-thumb-view-header .table-responsive .top .action-btns .dt-buttons .btn
{
    padding: .9rem .938rem;
}
.data-list-view-header .table-responsive .top .dataTables_length,
.data-thumb-view-header .table-responsive .top .dataTables_length
{
    display: inline-block;
}
.data-list-view-header .table-responsive .top .dataTables_length .custom-select,
.data-thumb-view-header .table-responsive .top .dataTables_length .custom-select
{
    font-size: 1rem;

    width: 8.714rem;
    height: 3rem;

    border: 1px solid #dae1e7;
    border-radius: 1.428rem;
    background-position: -webkit-calc(100% - 12px) 13px, -webkit-calc(100% - 20px) 13px, 100% 0;
    background-position:    -moz-calc(100% - 12px) 13px, -moz-calc(100% - 20px) 13px, 100% 0;
    background-position:         calc(100% - 12px) 13px, calc(100% - 20px) 13px, 100% 0;
}
.data-list-view-header .table-responsive .top .dataTables_length .custom-select:focus,
.data-thumb-view-header .table-responsive .top .dataTables_length .custom-select:focus
{
    -webkit-box-shadow: none;
            box-shadow: none;
}
.data-list-view-header .table-responsive .top .dataTables_filter,
.data-thumb-view-header .table-responsive .top .dataTables_filter
{
    display: inline-block;
}
.data-list-view-header .table-responsive .top .dataTables_filter .form-control,
.data-thumb-view-header .table-responsive .top .dataTables_filter .form-control
{
    padding: 1.45rem 2.8rem !important;

    border: 1px solid #dae1e7; 
    border-radius: 1.428rem;
}
.data-list-view-header .table-responsive .top .dataTables_filter label,
.data-thumb-view-header .table-responsive .top .dataTables_filter label
{
    position: relative;
}
.data-list-view-header .table-responsive .top .dataTables_filter label:after,
.data-thumb-view-header .table-responsive .top .dataTables_filter label:after
{
    font-family: 'feather';

    position: absolute;
    top: .928rem; 
    left: 1.428rem;

    content: '\e8bd';
}

.data-list-view-header .table-responsive .dataTables_wrapper .dataTables_paginate ul.pagination,
.data-thumb-view-header .table-responsive .dataTables_wrapper .dataTables_paginate ul.pagination
{
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
}

.data-list-view-header .add-new-data-sidebar .overlay-bg,
.data-thumb-view-header .add-new-data-sidebar .overlay-bg
{
    position: fixed;
    z-index: 1032; 
    top: 0;
    left: 0;

    display: none;

    width: 100%;
    height: 100%;

    -webkit-transition: all .3s ease;
       -moz-transition: all .3s ease;
         -o-transition: all .3s ease;
            transition: all .3s ease;

    opacity: 0;
    background: rgba(0, 0, 0, .2);
}
.data-list-view-header .add-new-data-sidebar .overlay-bg.show,
.data-thumb-view-header .add-new-data-sidebar .overlay-bg.show
{
    display: block; 

    opacity: 1;
}

.data-list-view-header .add-new-data-sidebar .add-new-data,
.data-thumb-view-header .add-new-data-sidebar .add-new-data
{
    position: fixed;
    z-index: 1033;
    top: 0;
    right: 0;
    left: auto;

    overflow: hidden; 

    width: 28.57rem;
    max-width: 90vw;
    height: 100vh;

    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;
    -webkit-transform: translateX(100%);
       -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
         -o-transform: translateX(100%);
            transform: translateX(100%);

    background: #fff;
    -webkit-box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
            box-shadow: 0 0 15px 0 rgba(0, 0, 0, .05);
}
.data-list-view-header .add-new-data-sidebar .add-new-data.show,
.data-thumb-view-header .add-new-data-sidebar .add-new-data.show
{
    -webkit-transform: translateX(0%);
       -moz-transform: translateX(0%);
        -ms-transform: translateX(0%);
         -o-transform: translateX(0%);
            transform: translateX(0%);
}
.data-list-view-header .add-new-data-sidebar .add-new-data .new-data-title,
.data-thumb-view-header .add-new-data-sidebar .add-new-data .new-data-title
{
    padding-bottom: .714rem;

    border-bottom: 1px solid rgba(0, 0, 0, .1);
}
.data-list-view-header .add-new-data-sidebar .add-new-data .new-data-title .hide-data-sidebar,
.data-thumb-view-header .add-new-data-sidebar .add-new-data .new-data-title .hide-data-sidebar
{
    position: relative;
}
.data-list-view-header .add-new-data-sidebar .add-new-data .new-data-title .hide-data-sidebar i,
.data-thumb-view-header .add-new-data-sidebar .add-new-data .new-data-title .hide-data-sidebar i
{
    font-size: 1.71rem;

    position: absolute;
    top: -.1428rem;
    right: 0;

    cursor: pointer;
}
.data-list-view-header .add-new-data-sidebar .add-new-data .data-items,
.data-thumb-view-header .add-new-data-sidebar .add-new-data .data-items
{
    position: relative; 

    height: -webkit-calc(100% - 10rem);
    height:    -moz-calc(100% - 10rem);
    height:         calc(100% - 10rem);

    border-bottom: 1px solid rgba(0, 0, 0, .1);
}
.data-list-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col,
.data-thumb-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col
{
    margin-top: 1.25rem;
}
.data-list-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col.data-list-upload,
.data-thumb-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col.data-list-upload
{
    margin-top: 2rem;
}
.data-list-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col .dropzone,
.data-thumb-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col .dropzone
{
    min-height: 14.285rem;
}
.data-list-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col .dropzone .dz-message,
.data-thumb-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col .dropzone .dz-message
{
    height: auto;
}
.data-list-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col .dropzone .dz-message:before,
.data-thumb-view-header .add-new-data-sidebar .add-new-data .data-items .data-field-col .dropzone .dz-message:before
{
    font-size: 2.857rem;
}

table.data-list-view.dataTable,
table.data-thumb-view.dataTable
{
    padding: 0 .7rem; 

    border-spacing: 0 1.3rem;
}
table.data-list-view.dataTable thead th,
table.data-thumb-view.dataTable thead th
{
    font-weight: 600; 

    padding: .714rem 1.785rem;
}
table.data-list-view.dataTable thead th input:focus,
table.data-thumb-view.dataTable thead th input:focus
{
    outline: 0;
}
table.data-list-view.dataTable thead th:first-child,
table.data-thumb-view.dataTable thead th:first-child
{
    padding-left: 0;
}
table.data-list-view.dataTable thead .sorting_asc,
table.data-list-view.dataTable thead .sorting_desc,
table.data-list-view.dataTable thead .sorting,
table.data-thumb-view.dataTable thead .sorting_asc,
table.data-thumb-view.dataTable thead .sorting_desc,
table.data-thumb-view.dataTable thead .sorting
{
    padding-right: inherit;
}
table.data-list-view.dataTable thead .sorting_asc:before,
table.data-list-view.dataTable thead .sorting_desc:before,
table.data-list-view.dataTable thead .sorting:before,
table.data-thumb-view.dataTable thead .sorting_asc:before,
table.data-thumb-view.dataTable thead .sorting_desc:before,
table.data-thumb-view.dataTable thead .sorting:before
{
    font-size: .7rem;

    top: .642rem; 
    left: .857rem;
}
table.data-list-view.dataTable thead .sorting_asc:after,
table.data-list-view.dataTable thead .sorting_desc:after,
table.data-list-view.dataTable thead .sorting:after,
table.data-thumb-view.dataTable thead .sorting_asc:after,
table.data-thumb-view.dataTable thead .sorting_desc:after,
table.data-thumb-view.dataTable thead .sorting:after
{
    font-size: .7rem;

    top: 1.071rem; 
    left: .857rem;
}
table.data-list-view.dataTable thead .dt-checkboxes-select-all input,
table.data-thumb-view.dataTable thead .dt-checkboxes-select-all input
{
    position: relative; 

    width: 0;
}
table.data-list-view.dataTable thead .dt-checkboxes-select-all input:before,
table.data-thumb-view.dataTable thead .dt-checkboxes-select-all input:before
{
    position: absolute; 

    overflow: hidden;

    width: 1.071rem;
    height: 1.071rem;
    padding: 0;

    content: '';
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
            transition: all .2s ease;
    -webkit-transform: rotate(-90deg);
       -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
         -o-transform: rotate(-90deg);
            transform: rotate(-90deg);

    border: 2px solid #b4b4b4;
    border-radius: 2px;
}
table.data-list-view.dataTable thead .dt-checkboxes-select-all input:after,
table.data-thumb-view.dataTable thead .dt-checkboxes-select-all input:after
{
    font-family: 'feather';
    font-size: .75rem;
    line-height: 1.2;

    position: absolute;

    overflow: hidden; 

    width: .928rem;
    height: 1rem;

    content: '\e83f';
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
    -webkit-transform: translate(100%);
       -moz-transform: translate(100%);
        -ms-transform: translate(100%);
         -o-transform: translate(100%);
            transform: translate(100%);
    -webkit-transform-origin: right;
       -moz-transform-origin: right;
        -ms-transform-origin: right;
         -o-transform-origin: right;
            transform-origin: right;

    opacity: 0;
    color: #fff;
    border: 2px solid #46003A;
    background-color: #46003A;
}
table.data-list-view.dataTable thead .dt-checkboxes-select-all input:active:checked:after,
table.data-thumb-view.dataTable thead .dt-checkboxes-select-all input:active:checked:after
{
    -webkit-transform: translate(3px);
       -moz-transform: translate(3px);
        -ms-transform: translate(3px);
         -o-transform: translate(3px);
            transform: translate(3px);
}
table.data-list-view.dataTable thead .dt-checkboxes-select-all input:checked:before,
table.data-thumb-view.dataTable thead .dt-checkboxes-select-all input:checked:before
{
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg); 

    border: 2px solid #46003A;
}
table.data-list-view.dataTable thead .dt-checkboxes-select-all input:checked:after,
table.data-thumb-view.dataTable thead .dt-checkboxes-select-all input:checked:after
{
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
    -webkit-transform: translate(0);
       -moz-transform: translate(0);
        -ms-transform: translate(0);
         -o-transform: translate(0);
            transform: translate(0); 

    opacity: 1;
}
table.data-list-view.dataTable tbody tr,
table.data-thumb-view.dataTable tbody tr
{
    cursor: pointer;
    -webkit-transition: all .3s ease;
       -moz-transition: all .3s ease;
         -o-transition: all .3s ease;
            transition: all .3s ease;

    border-radius: .5rem; 
    background-color: #fff;
    -webkit-box-shadow: rgba(0, 0, 0, .05) 0 4px 20px 0;
            box-shadow: rgba(0, 0, 0, .05) 0 4px 20px 0;
}
table.data-list-view.dataTable tbody tr:hover,
table.data-thumb-view.dataTable tbody tr:hover
{
    -webkit-transition: all .3s ease;
       -moz-transition: all .3s ease;
         -o-transition: all .3s ease;
            transition: all .3s ease; 
    -webkit-transform: translateY(-4px);
       -moz-transform: translateY(-4px);
        -ms-transform: translateY(-4px);
         -o-transform: translateY(-4px);
            transform: translateY(-4px);
}
table.data-list-view.dataTable tbody tr td:first-child,
table.data-thumb-view.dataTable tbody tr td:first-child
{
    padding-left: 1rem;

    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
}
table.data-list-view.dataTable tbody tr td:last-child,
table.data-thumb-view.dataTable tbody tr td:last-child
{
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
}
table.data-list-view.dataTable tbody tr.selected td,
table.data-thumb-view.dataTable tbody tr.selected td
{
    border-radius: 0;
}
table.data-list-view.dataTable tbody td,
table.data-thumb-view.dataTable tbody td
{
    padding: 1.357rem;

    vertical-align: middle; 

    border: none;
}
table.data-list-view.dataTable tbody td input:focus,
table.data-thumb-view.dataTable tbody td input:focus
{
    outline: 0;
}
table.data-list-view.dataTable tbody td.product-name,
table.data-thumb-view.dataTable tbody td.product-name
{
    font-weight: 500;
}
table.data-list-view.dataTable tbody td:focus,
table.data-thumb-view.dataTable tbody td:focus
{
    outline: 0;
}
table.data-list-view.dataTable tbody td.dt-checkboxes-cell input,
table.data-thumb-view.dataTable tbody td.dt-checkboxes-cell input
{
    position: relative; 

    width: 0;
}
table.data-list-view.dataTable tbody td.dt-checkboxes-cell input:before,
table.data-thumb-view.dataTable tbody td.dt-checkboxes-cell input:before
{
    position: absolute;
    left: -8px;

    overflow: hidden;

    width: 1.071rem;
    height: 1.071rem;
    padding: 0;

    content: '';
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 
    -webkit-transform: rotate(-90deg);
       -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
         -o-transform: rotate(-90deg);
            transform: rotate(-90deg);

    border: 2px solid #b4b4b4;
    border-radius: 2px;
}
table.data-list-view.dataTable tbody td.dt-checkboxes-cell input:after,
table.data-thumb-view.dataTable tbody td.dt-checkboxes-cell input:after
{
    font-family: 'feather';
    font-size: .75rem;

    position: absolute;
    left: -.4285rem;

    overflow: hidden;

    width: .857rem;
    height: 1rem;

    content: '\e83f';
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 
    -webkit-transform: rotate(-90deg) translate(100%);
       -moz-transform: rotate(-90deg) translate(100%);
        -ms-transform: rotate(-90deg) translate(100%);
         -o-transform: rotate(-90deg) translate(100%);
            transform: rotate(-90deg) translate(100%);
    -webkit-transform-origin: right;
       -moz-transform-origin: right;
        -ms-transform-origin: right;
         -o-transform-origin: right;
            transform-origin: right;

    opacity: 0;
    color: #fff;
    background-color: #46003A;
}
table.data-list-view.dataTable tbody td.dt-checkboxes-cell input:active:checked:after,
table.data-thumb-view.dataTable tbody td.dt-checkboxes-cell input:active:checked:after
{
    -webkit-transform: translate(3px);
       -moz-transform: translate(3px);
        -ms-transform: translate(3px);
         -o-transform: translate(3px);
            transform: translate(3px);
}
table.data-list-view.dataTable tbody td.dt-checkboxes-cell input:checked:before,
table.data-thumb-view.dataTable tbody td.dt-checkboxes-cell input:checked:before
{
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);

    border: 2px solid #46003A;
}
table.data-list-view.dataTable tbody td.dt-checkboxes-cell input:checked:after,
table.data-thumb-view.dataTable tbody td.dt-checkboxes-cell input:checked:after
{
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
    -webkit-transform: rotate(0deg) translate(0);
       -moz-transform: rotate(0deg) translate(0);
        -ms-transform: rotate(0deg) translate(0);
         -o-transform: rotate(0deg) translate(0);
            transform: rotate(0deg) translate(0); 

    opacity: 1;
}
table.data-list-view.dataTable tbody td .progress,
table.data-thumb-view.dataTable tbody td .progress
{
    margin-bottom: 0;

    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
}

table.data-thumb-view.dataTable tbody tr td
{
    padding-top: .714rem;
    padding-bottom: .714rem;
}
table.data-thumb-view.dataTable tbody tr td.product-img img
{
    height: 7.857rem;
}

@media (max-width: 768px)
{
    #data-list-view .table-responsive .top,
    #data-thumb-view .table-responsive .top
    {
                flex-direction: column; 

        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
           -moz-box-orient: vertical;
           -moz-box-direction: normal;
            -ms-flex-direction: column;
    }
    #data-list-view .table-responsive .dt-buttons,
    #data-thumb-view .table-responsive .dt-buttons
    {
        width: auto;

        text-align: left;
    }
    #data-list-view .table-responsive .dataTables_length,
    #data-list-view .table-responsive .dataTables_filter,
    #data-thumb-view .table-responsive .dataTables_length,
    #data-thumb-view .table-responsive .dataTables_filter
    {
        text-align: right;
    }
}

@media (max-width: 576px)
{
    .add-new-data-sidebar .data-items .data-list-upload .dropzone .dz-message:before
    {
        top: 3.428rem;
    }
}

_:-ms-lang(x) tbody tr td.dt-checkboxes-cell input,
_:-ms-lang(x) tbody tr th.dt-checkboxes-cell input,
_:-ms-lang(x) thead tr td.dt-checkboxes-cell input,
_:-ms-lang(x) thead tr th.dt-checkboxes-cell input,
.data-list-view.dataTable tbody tr td.dt-checkboxes-cell input,
.data-list-view.dataTable tbody tr th.dt-checkboxes-cell input,
.data-list-view.dataTable thead tr td.dt-checkboxes-cell input,
.data-list-view.dataTable thead tr th.dt-checkboxes-cell input,
.data-thumb-view.dataTable tbody tr td.dt-checkboxes-cell input,
.data-thumb-view.dataTable tbody tr th.dt-checkboxes-cell input,
.data-thumb-view.dataTable thead tr td.dt-checkboxes-cell input,
.data-thumb-view.dataTable thead tr th.dt-checkboxes-cell input
{
    width: auto;
}

_:-ms-lang(x) .table-responsive .top .dataTables_filter .form-control,
.data-list-view.dataTable .table-responsive .top .dataTables_filter .form-control,
.data-thumb-view.dataTable .table-responsive .top .dataTables_filter .form-control
{
    height: 1.4rem !important;
    padding: 0rem .5rem !important;
}
_:-ms-lang(x) .table-responsive .top .dataTables_filter .form-control:after,
.data-list-view.dataTable .table-responsive .top .dataTables_filter .form-control:after,
.data-thumb-view.dataTable .table-responsive .top .dataTables_filter .form-control:after
{
    display: none;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    #data-list-view .table-responsive .top .dataTables_filter .form-control,
    #data-thumb-view .table-responsive .top .dataTables_filter .form-control
    {
        height: 3rem !important;
        padding: 0rem 1rem !important;
    }
    #data-list-view .table-responsive .top .dataTables_filter label:after,
    #data-thumb-view .table-responsive .top .dataTables_filter label:after
    {
        display: none;
    }
}

@supports (-moz-osx-font-smoothing: auto)
{
    .data-list-view input,
    .data-thumb-view input
    {
        width: auto !important;
    }
}

@media not all and (min-resolution: .001dpcm)
{
    @supports (-webkit-appearance: none)
    {
        table.data-list-view tbody td.dt-checkboxes-cell input:after,
        table.data-thumb-view tbody td.dt-checkboxes-cell input:after
        {
            left: -.485rem;
        }
        .data-list-view .dt-checkboxes-cell input,
        .data-thumb-view .dt-checkboxes-cell input
        {
            top: -10px; 

            -webkit-appearance: none;
        }
    }
}

.data-list-view .mac-checkbox,
.data-thumb-view .mac-checkbox
{
    top: -10px; 

    -webkit-appearance: none;
}
