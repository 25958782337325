/*========================================================
        DARK LAYOUT
=========================================================*/
.tippy-popper
{
    border: none;
    border-radius: .5rem;

    -webkit-filter: drop-shadow(0 0 7px rgba(0, 0, 0, .5));
            filter: drop-shadow(0 0 7px rgba(0, 0, 0, .5));
}
.tippy-popper .tippy-tooltip
{
    width: auto !important;
}
.tippy-popper .tippy-tooltip .tippy-arrow
{
    border-bottom-color: #46003A;
}
.tippy-popper .tippy-tooltip .tippy-content .shepherd-content
{
    padding: 1.3rem 1rem;

    border-radius: .5rem; 
    background-color: #46003A;
}
.tippy-popper .tippy-tooltip .tippy-content .shepherd-content .shepherd-header
{
    padding: 0;
}
.tippy-popper .tippy-tooltip .tippy-content .shepherd-content .shepherd-text
{
    font-size: 1rem; 

    color: #fff;
}
.tippy-popper .tippy-tooltip .tippy-content .shepherd-content .shepherd-footer
{
    padding: 0;
}
.tippy-popper .tippy-tooltip .tippy-content .shepherd-content .shepherd-footer .shepherd-buttons li
{
    margin: 0;
}
.tippy-popper .tippy-tooltip .tippy-content .shepherd-content .shepherd-footer .shepherd-buttons .shepherd-button
{
    margin: 0 5px; 
    padding: .7rem 2rem;

    color: #fff;
    border: 1px solid #fff;
    border-radius: 6px;
    background-color: transparent;
}
.tippy-popper .tippy-tooltip .tippy-content .shepherd-content .shepherd-footer .shepherd-buttons .shepherd-button:hover
{
    background-color: rgba(255, 255, 255, .1);
}
.tippy-popper .tippy-tooltip .tippy-content [data-shepherd-step-id='step-4'] .shepherd-footer .shepherd-buttons
{
    text-align: center;
}

.tippy-popper[x-placement='top'] .tippy-arrow
{
    border-color: transparent;
    border-top-color: #46003A;
}

.tippy-popper[x-placement='bottom'] .tippy-arrow
{
    border-color: transparent;
    border-bottom-color: #46003A;
}

.tippy-popper[x-placement='right'] .tippy-arrow
{
    border-color: transparent;
    border-right-color: #46003A;
}

.tippy-popper[x-placement='left'] .tippy-arrow
{
    border-color: transparent;
    border-left-color: #46003A;
}
