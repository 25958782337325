/*========================================================
        DARK LAYOUT
=========================================================*/
a:focus
{
    outline: none;
}

.font-small-1
{
    font-size: .7rem !important;
}

.font-small-2
{
    font-size: .8rem !important;
}

.font-small-3
{
    font-size: .9rem !important;
}

.font-medium-1
{
    font-size: 1.1rem !important;
}

.font-medium-2
{
    font-size: 1.2rem !important;
}

.font-medium-3
{
    font-size: 1.3rem !important;
}

.font-medium-4
{
    font-size: 1.4rem !important;
}

.font-medium-5
{
    font-size: 1.5rem !important;
}

.font-large-1
{
    font-size: 2rem !important;
}

.font-large-2
{
    font-size: 3rem !important;
}

.font-large-3
{
    font-size: 4rem !important;
}

.font-large-4
{
    font-size: 5rem !important;
}

.font-large-5
{
    font-size: 6rem !important;
}

.text-bold-300
{
    font-weight: 300;
}

.text-bold-400
{
    font-weight: 400;
}

.text-bold-500
{
    font-weight: 500;
}

.text-bold-600
{
    font-weight: 600;
}

.text-bold-700
{
    font-weight: 700;
}

.text-italic
{
    font-style: italic;
}

.text-highlight
{
    padding: 4px 6px;
}

code
{
    font-size: 90%;

    padding: .1rem .4rem;

    color: #e83e8c;
    border-radius: .25rem; 
    background-color: #eee;
}

pre
{
    background-color: #f7f7f9;
}
pre code
{
    background-color: transparent !important;
}

.bd-example-row .row + .row
{
    margin-top: 1rem;
}

.bd-example-row .row > .col,
.bd-example-row .row > [class^='col-']
{
    padding-top: .75rem;
    padding-bottom: .75rem;

    border: 1px solid rgba(86, 61, 124, .2); 
    background-color: rgba(86, 61, 124, .15);
}

.bd-example-row .flex-items-top,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-bottom
{
    min-height: 6rem;

    background-color: rgba(255, 0, 0, .1);
}

.bd-example-row-flex-cols .row
{
    min-height: 10rem;

    background-color: rgba(255, 0, 0, .1);
}

.bd-highlight
{
    border: 1px solid rgba(86, 61, 124, .15); 
    background-color: rgba(86, 61, 124, .15);
}

.example-container
{
    width: 800px;
    width: 100%;
    margin-right: auto;
    margin-left: auto; 
    padding-right: 14px;
    padding-left: 14px;
}

.example-row
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin-right: -14px;
    margin-left: -14px; 

    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}

.example-content-main
{
    position: relative;

    width: 100%;
    padding-right: 14px;
    padding-left: 14px;
}
@media (min-width: 576px)
{
    .example-content-main
    {
        max-width: 50%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
    }
}
@media (min-width: 992px)
{
    .example-content-main
    {
        max-width: 66.66667%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 66.66667%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 66.66667%;
                flex: 0 0 66.66667%;
    }
}

.example-content-secondary
{
    position: relative;

    width: 100%;
    padding-right: 14px;
    padding-left: 14px;
}
@media (min-width: 576px)
{
    .example-content-secondary
    {
        max-width: 50%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 50%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 50%;
                flex: 0 0 50%;
    }
}
@media (min-width: 992px)
{
    .example-content-secondary
    {
        max-width: 33.33333%; 

        -webkit-box-flex: 0;
        -webkit-flex: 0 0 33.33333%;
           -moz-box-flex: 0;
            -ms-flex: 0 0 33.33333%;
                flex: 0 0 33.33333%;
    }
}

.table-white-space th,
.table-white-space td
{
    white-space: nowrap;
}

.table thead th
{
    vertical-align: top;
}

.table th,
.table td
{
    padding: 1rem;

    vertical-align: middle;
}

.table th
{
    font-size: .85rem;
}

.table-inverse
{
    color: #fff;
    background-color: #00cfe8;
}

.table-hover-animation
{
    background-color: #f8f8f8;
}
.table-hover-animation thead th
{
    border-top: 2px solid #f8f8f8;
    border-bottom: 0;
    background-color: #fff;
}
.table-hover-animation th,
.table-hover-animation td
{
    border: 0;
}
.table-hover-animation tbody tr
{
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;

    background-color: #fff;
}
.table-hover-animation tbody tr:hover
{
    -webkit-transform: translateY(-4px);
       -moz-transform: translateY(-4px);
        -ms-transform: translateY(-4px);
         -o-transform: translateY(-4px);
            transform: translateY(-4px);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .table-hover-animation tr:hover
    {
        border-bottom-color: #f8f8f8;
    }
}

.form-control::-webkit-input-placeholder
{
    font-size: .85rem;

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 

    color: rgba(34, 41, 47, .4);
}

.form-control:-moz-placeholder
{
    font-size: .85rem;

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 

    color: rgba(34, 41, 47, .4);
}

.form-control::-moz-placeholder
{
    font-size: .85rem;

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 

    color: rgba(34, 41, 47, .4);
}

.form-control:-ms-input-placeholder
{
    font-size: .85rem;

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 

    color: rgba(34, 41, 47, .4);
}

.form-control::placeholder
{
    font-size: .85rem;

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 

    color: rgba(34, 41, 47, .4);
}

.form-control:-ms-input-placeholder
{
    color: #c5c5c5;
}

.form-control:focus::-webkit-input-placeholder
{
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 
    -webkit-transform: translate(5px);
            transform: translate(5px);
}

.form-control:focus:-moz-placeholder
{
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 
    -moz-transform: translate(5px);
         transform: translate(5px);
}

.form-control:focus::-moz-placeholder
{
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 
    -moz-transform: translate(5px);
         transform: translate(5px);
}

.form-control:focus:-ms-input-placeholder
{
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 
    -ms-transform: translate(5px);
        transform: translate(5px);
}

.form-control:focus::placeholder
{
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease; 
    -webkit-transform: translate(5px);
       -moz-transform: translate(5px);
        -ms-transform: translate(5px);
         -o-transform: translate(5px);
            transform: translate(5px);
}

.form-control:focus ~ .form-control-position i
{
    color: #46003A;
}

/* Form specific Scss */
label
{
    font-size: .85rem;

    margin-bottom: 0;
    padding-left: .2rem; 

    color: #464646;
}

textarea.form-control
{
    font-size: 1rem; 
    line-height: 1.6rem;
}

.form-group
{
    margin-bottom: 1.5rem;
}

.form-control
{
    color: #5f5f5f; 
    border: 1px solid #d9d9d9;
}

.form-section
{
    line-height: 3rem;

    margin-bottom: 20px;

    color: #b8c2cc;
    border-bottom: 1px solid #b8c2cc;
}
.form-section i
{
    font-size: 20px;

    margin-right: 10px; 
    margin-left: 5px;
}

/* .form-body{
    overflow: hidden;
} */
.form-actions
{
    margin-top: 20px; 
    padding: 20px 0;

    border-top: 1px solid #dfdfdf;
}
.form-actions.filled
{
    background-color: #f8f8f8;
}
.form-actions.center
{
    text-align: center;
}
.form-actions.right
{
    text-align: right;
}
.form-actions.top
{
    margin-top: 0;
    margin-bottom: 20px; 

    border-top: 0;
    border-bottom: 1px solid #dfdfdf;
}
@media (max-width: 767.98px)
{
    .form-actions .buttons-group
    {
        float: left !important;

        margin-bottom: 10px;
    }
}

@media (min-width: 576px)
{
    .form-horizontal .form-group .label-control
    {
        text-align: right;
    }
}

.form-control.form-control-lg
{
    padding: 1rem;
}
.form-control.form-control-lg::-webkit-input-placeholder
{
    font-size: 1.25rem;
}
.form-control.form-control-lg:-moz-placeholder
{
    font-size: 1.25rem;
}
.form-control.form-control-lg::-moz-placeholder
{
    font-size: 1.25rem;
}
.form-control.form-control-lg:-ms-input-placeholder
{
    font-size: 1.25rem;
}
.form-control.form-control-lg::placeholder
{
    font-size: 1.25rem;
}

.form-control.form-control-sm
{
    padding: .6rem;
}
.form-control.form-control-sm::-webkit-input-placeholder
{
    font-size: .7rem;
}
.form-control.form-control-sm:-moz-placeholder
{
    font-size: .7rem;
}
.form-control.form-control-sm::-moz-placeholder
{
    font-size: .7rem;
}
.form-control.form-control-sm:-ms-input-placeholder
{
    font-size: .7rem;
}
.form-control.form-control-sm::placeholder
{
    font-size: .7rem;
}

.custom-file-label
{
    padding: .4rem .7rem;
}
.custom-file-label::after
{
    height: auto; 
    padding: .4rem .7rem;
}

select.form-control:not([multiple='multiple'])
{
    padding-right: 1.5rem; 

    background-image: url('../../app-assets/images/pages/arrow-down.png');
    background-repeat: no-repeat;
    background-position: -webkit-calc(100% - 12px) 13px, -webkit-calc(100% - 20px) 13px, 100% 0;
    background-position:    -moz-calc(100% - 12px) 13px, -moz-calc(100% - 20px) 13px, 100% 0;
    background-position:         calc(100% - 12px) 13px, calc(100% - 20px) 13px, 100% 0;
    -webkit-background-size: 12px 12px,    10px 10px;
            background-size: 12px 12px,    10px 10px;

    -webkit-appearance: none;
       -moz-appearance: none;
}

.custom-select
{
       -moz-appearance: none;
    -webkit-appearance: none;
}

.input-group-addon
{
    border-color: #babfc7; 
    background-color: #eceff1;
}

.custom-control-label::before,
.custom-control-label::after
{
    top: .08rem; 

    width: 1.143rem;
    height: 1.143rem;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before
{
    border-color: #46003A; 
    background-color: #46003A;
}

/* Floating label Group */
.form-label-group
{
    position: relative;

    margin-bottom: 1.5rem;
}
.form-label-group .form-control-lg ~ label
{
    font-size: 1.25rem;

    padding: 1rem;
}
.form-label-group .form-control-sm ~ label
{
    font-size: .7rem;
}
.form-label-group > label
{
    font-size: .85rem;

    position: absolute;
    top: 0;
    left: 0;

    display: block;

    padding: .6rem;

    -webkit-transition: all .25s ease-in-out;
       -moz-transition: all .25s ease-in-out;
         -o-transition: all .25s ease-in-out;
            transition: all .25s ease-in-out;

    opacity: 0; 
    color: rgba(34, 41, 47, .4);
}
.form-label-group > input,
.form-label-group textarea
{
    padding: .6rem;
}
.form-label-group > input:focus:not(:placeholder-shown) ~ label,
.form-label-group > input:not(:active):not(:placeholder-shown) ~ label,
.form-label-group textarea:focus:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:active):not(:placeholder-shown) ~ label
{
    -webkit-transition: all .25s ease-in-out;
       -moz-transition: all .25s ease-in-out;
         -o-transition: all .25s ease-in-out;
            transition: all .25s ease-in-out;

    opacity: 1; 
    color: #46003A !important;
}
.form-label-group > input:not(:focus):not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:focus):not(:placeholder-shown) ~ label
{
    color: rgba(34, 41, 47, .4) !important;
}
.form-label-group > input:not(:placeholder-shown) ~ label,
.form-label-group textarea:not(:placeholder-shown) ~ label
{
    font-size: .7rem;

    top: -20px;
    left: 3px; 

    padding: .25rem 0;
}
.form-label-group.has-icon-left > label
{
    left: 36px;
}

/* VueSax Custom Checkbox */
.vs-checkbox-con
{
    position: relative;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin: .25rem 0; 

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
    -ms-flex-pack: start;
            justify-content: flex-start;
}
.vs-checkbox-con input
{
    position: absolute;
    z-index: 200;
    top: 0; 

    width: 100%;
    height: 100%;

    cursor: pointer;

    opacity: 0;
}
.vs-checkbox-con input:checked ~ .vs-checkbox
{
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);

    border-color: #46003A;
}
.vs-checkbox-con input:checked ~ .vs-checkbox .vs-checkbox--check
{
    -webkit-transform: translate(0);
       -moz-transform: translate(0);
        -ms-transform: translate(0);
         -o-transform: translate(0);
            transform: translate(0);

    background-color: #46003A;
}
.vs-checkbox-con input:checked ~ .vs-checkbox .vs-checkbox--check .vs-icon
{
    line-height: 1.2;

    -webkit-transform: translate(0);
       -moz-transform: translate(0);
        -ms-transform: translate(0);
         -o-transform: translate(0);
            transform: translate(0);

    opacity: 1;
}
.vs-checkbox-con input:checked ~ .vs-checkbox.vs-checkbox-sm .vs-checkbox--check .vs-icon
{
    font-size: .75rem;
}
.vs-checkbox-con input:checked ~ .vs-checkbox.vs-checkbox-lg .vs-checkbox--check .vs-icon
{
    font-size: 1.2rem;
}
.vs-checkbox-con input:active:checked + .vs-checkbox .vs-checkbox--check
{
    -webkit-transform: translate(3px);
       -moz-transform: translate(3px);
        -ms-transform: translate(3px);
         -o-transform: translate(3px);
            transform: translate(3px);

    background-color: #46003A;
}
.vs-checkbox-con input:active:checked + .vs-checkbox .vs-checkbox--check .vs-icon
{
    -webkit-transform: translate(6px);
       -moz-transform: translate(6px);
        -ms-transform: translate(6px);
         -o-transform: translate(6px);
            transform: translate(6px); 

    opacity: 1;
}
.vs-checkbox-con input:disabled
{
    cursor: default;
    pointer-events: none;
}
.vs-checkbox-con input:disabled + .vs-checkbox
{
    cursor: default;

    opacity: .5;
}
.vs-checkbox-con .vs-checkbox
{
    position: relative;

    overflow: hidden;

    width: 20px;
    height: 20px;
    margin-right: .5rem; 

    cursor: pointer;
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
    -webkit-transform: rotate(-90deg);
       -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
         -o-transform: rotate(-90deg);
            transform: rotate(-90deg);

    border-width: 2px;
    border-style: solid;
    border-color: #b8c2cc;
    border-radius: 2px;
}
.vs-checkbox-con .vs-checkbox.vs-checkbox-sm
{
    width: 15px;
    height: 15px;
}
.vs-checkbox-con .vs-checkbox.vs-checkbox-lg
{
    width: 24px;
    height: 24px;
}
.vs-checkbox-con .vs-checkbox .vs-checkbox--check
{
    position: absolute;
    z-index: 10; 
    left: 0;

    width: 100%;
    height: 100%;

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
    -webkit-transform: translate(100%);
       -moz-transform: translate(100%);
        -ms-transform: translate(100%);
         -o-transform: translate(100%);
            transform: translate(100%);
    -webkit-transform-origin: right;
       -moz-transform-origin: right;
        -ms-transform-origin: right;
         -o-transform-origin: right;
            transform-origin: right;
}
.vs-checkbox-con .vs-checkbox .vs-checkbox--check .vs-icon
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    color: #fff; 

    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
    -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}

/* VueSax Custom Radio */
.vs-radio-con
{
    position: relative;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    margin: .25rem 0; 

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
       -moz-box-pack: start;
    -ms-flex-pack: start;
            justify-content: flex-start;
}
.vs-radio-con input
{
    position: absolute;
    z-index: 200;

    width: 100%;
    height: 100%;

    cursor: pointer; 

    opacity: 0;
}
.vs-radio-con input:checked ~ .vs-radio
{
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);

    outline: 0;
}
.vs-radio-con input:checked ~ .vs-radio .vs-radio--circle
{
    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1); 

    opacity: 1;
    background: #46003A;
    -webkit-box-shadow: 0 3px 12px 0 rgba(115, 103, 240, .4);
            box-shadow: 0 3px 12px 0 rgba(115, 103, 240, .4);
}
.vs-radio-con input:checked ~ .vs-radio .vs-radio--border
{
    -webkit-transform: scale(.3);
       -moz-transform: scale(.3);
        -ms-transform: scale(.3);
         -o-transform: scale(.3);
            transform: scale(.3); 

    opacity: 0;
}
.vs-radio-con input:disabled
{
    cursor: default;
    pointer-events: none;
}
.vs-radio-con input:disabled + .vs-radio
{
    cursor: default;

    opacity: .5;
}
.vs-radio-con input:active ~ .vs-radio
{
    -webkit-transform: scale(1.1);
       -moz-transform: scale(1.1);
        -ms-transform: scale(1.1);
         -o-transform: scale(1.1);
            transform: scale(1.1);
}
.vs-radio-con .vs-radio
{
    position: relative;

    width: 18px;
    height: 18px;
    margin-right: .5rem; 

    cursor: pointer;
    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
}
.vs-radio-con .vs-radio.vs-radio-sm
{
    width: 13px;
    height: 13px;
}
.vs-radio-con .vs-radio.vs-radio-lg
{
    width: 22px;
    height: 22px;
}
.vs-radio-con .vs-radio .vs-radio--border,
.vs-radio-con .vs-radio .vs-radio--circle
{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;

    border-radius: 50%;
}
.vs-radio-con .vs-radio .vs-radio--border
{
    border: 2px solid #b8c2cc; 
    background: transparent;
}
.vs-radio-con .vs-radio .vs-radio--circle
{
    -webkit-transform: scale(.1);
       -moz-transform: scale(.1);
        -ms-transform: scale(.1);
         -o-transform: scale(.1);
            transform: scale(.1); 

    opacity: 0;
}

/* Switches */
.custom-switch
{
    padding-left: 0;
    /*For Switch label*/
    /*For Switch Handle Animation*/
}
.custom-switch .custom-control-label
{
    width: 3.1rem;
    height: 1.571rem;
    padding: 0;
    /* For bg color of switch*/
    /*For Switch handle*/
    /*For Switch text*/
}
.custom-switch .custom-control-label::before
{
    top: 0;
    left: -.2857rem;
    left: 0; 

    height: 1.571rem;

    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;

    border: none;
    background-color: #e2e2e2;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}
.custom-switch .custom-control-label:after
{
    position: absolute;
    top: 2px;
    left: 2px;

    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; 
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;

    background-color: #fff;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.custom-switch .custom-control-label .switch-text-left,
.custom-switch .custom-control-label .switch-text-right,
.custom-switch .custom-control-label .switch-icon-left,
.custom-switch .custom-control-label .switch-icon-right
{
    font-size: .7rem;

    position: absolute;
    top: 5px;

    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}
.custom-switch .custom-control-label .switch-text-left,
.custom-switch .custom-control-label .switch-icon-left
{
    left: 7px;

    color: #fff;
}
.custom-switch .custom-control-label .switch-text-right,
.custom-switch .custom-control-label .switch-icon-right
{
    right: 7px;
}
.custom-switch .custom-control-label:focus
{
    outline: 0;
}
.custom-switch .switch-label
{
    padding-left: 1rem;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::before
{
    -webkit-box-shadow: none;
            box-shadow: none;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after
{
    -webkit-transform: translateX(1.4rem);
       -moz-transform: translateX(1.4rem);
        -ms-transform: translateX(1.4rem);
         -o-transform: translateX(1.4rem);
            transform: translateX(1.4rem);
}
.custom-switch .custom-control-input:not(:checked) ~ .custom-control-label .switch-text-left
{
    opacity: 0;
}
.custom-switch .custom-control-input:not(:checked) ~ .custom-control-label .switch-text-right
{
    opacity: 1;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label .switch-text-right
{
    opacity: 0;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label .switch-text-left
{
    opacity: 1;
}
.custom-switch .custom-control-input:not(:disabled):active ~ .custom-control-label::before
{
    background-color: #e2e2e2;
}
.custom-switch.switch-md .custom-control-label
{
    width: 4rem;
    height: 1.571rem;
}
.custom-switch.switch-md .custom-control-label::before
{
    left: -.2857rem;

    width: 4rem;
    height: 1.571rem;

    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; 
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;

    border: none;
    background-color: #e2e2e2;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}
.custom-switch.switch-md .custom-control-label:after
{
    position: absolute;
    top: 2px;
    left: -2px; 

    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;

    background-color: #fff;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.custom-switch.switch-md .custom-control-label .switch-text-right,
.custom-switch.switch-md .custom-control-label .switch-icon-right
{
    right: 9px;
}
.custom-switch.switch-md .custom-control-label .switch-text-left,
.custom-switch.switch-md .custom-control-label .switch-icon-left
{
    left: 5px;
}
.custom-switch.switch-md .custom-control-input:checked ~ .custom-control-label::after
{
    -webkit-transform: translateX(2.3rem);
       -moz-transform: translateX(2.3rem);
        -ms-transform: translateX(2.3rem);
         -o-transform: translateX(2.3rem);
            transform: translateX(2.3rem);
}
.custom-switch.switch-lg .custom-control-label
{
    width: 5.5rem;
    height: 1.571rem;
}
.custom-switch.switch-lg .custom-control-label::before
{
    left: -.2857rem;

    width: 5.5rem;
    height: 1.571rem;

    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; 
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;

    border: none;
    background-color: #e2e2e2;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}
.custom-switch.switch-lg .custom-control-label:after
{
    position: absolute;
    top: 2px;
    left: -2px;

    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none; 
    -webkit-transition: all .25s ease;
       -moz-transition: all .25s ease;
         -o-transition: all .25s ease;
            transition: all .25s ease;

    background-color: #fff;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.custom-switch.switch-lg .custom-control-label .switch-text-right,
.custom-switch.switch-lg .custom-control-label .switch-icon-right
{
    right: 10px;
}
.custom-switch.switch-lg .custom-control-input:checked ~ .custom-control-label::after
{
    -webkit-transform: translateX(3.8rem);
       -moz-transform: translateX(3.8rem);
        -ms-transform: translateX(3.8rem);
         -o-transform: translateX(3.8rem);
            transform: translateX(3.8rem);
}

/* Textarea with Counter */
.counter-value
{
    font-size: .6rem;

    margin-right: 1rem; 
    padding: 1px 6px;

    color: #fff;
    border-radius: 0 0 5px 5px;
    background-color: #46003A;
}

/* Input box with icon divider */
.form-group.input-divider-left .form-control-position i
{
    padding: .2rem .5rem;

    border-right: 1px solid #d9d9d9;
}

.form-group.input-divider-right .form-control-position i
{
    padding: .2rem .5rem;

    border-left: 1px solid #d9d9d9;
}

/* input-groups-lg and input-groups-sm  */
.input-group-lg .form-control,
.input-group-sm .form-control
{
    padding: .7rem;
}

/* Bootstrap touchspin */
.bootstrap-touchspin.input-group
{
    width: 8.4rem;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.bootstrap-touchspin.input-group .form-control
{
    font-weight: 500; 

    height: auto;
    padding: 5px;

    text-align: center;

    border: 0;
    border-radius: 5px !important;
    background-color: #f8f8f8;
}
.bootstrap-touchspin.input-group .form-control:focus
{
    z-index: 1;

    -webkit-box-shadow: none;
            box-shadow: none;
}

.bootstrap-touchspin .bootstrap-touchspin-injected
{
    margin: 0 !important;
}
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up
{
    position: relative; 

    min-width: 22px;
    min-height: 22px;
    padding: 0;

    border-radius: 5px !important;
}
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down i,
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up i
{
    font-size: .8rem;

    position: relative;
    top: -1px;
}
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-down
{
    left: 12px;
}
.bootstrap-touchspin .bootstrap-touchspin-injected .bootstrap-touchspin-up
{
    right: 12px;
}

.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-down,
.bootstrap-touchspin.disabled-touchspin .bootstrap-touchspin-up
{
    cursor: default;

    opacity: .5; 
    background-color: rgba(0, 0, 0, .5) !important;
}

.bootstrap-touchspin.input-group-lg
{
    width: 9.375rem;
}
.bootstrap-touchspin.input-group-lg .touchspin.form-control
{
    height: auto !important;
}
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-down i,
.bootstrap-touchspin.input-group-lg .bootstrap-touchspin-up i
{
    font-size: 1rem;
}

.bootstrap-touchspin.input-group-sm
{
    width: 6.25rem;
}
.bootstrap-touchspin.input-group-sm .touchspin.form-control
{
    height: auto !important;
}
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down,
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up
{
    min-width: 16px;
    min-height: 16px;
    padding-top: 2.2px;
}
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-down i,
.bootstrap-touchspin.input-group-sm .bootstrap-touchspin-injected .bootstrap-touchspin-up i
{
    font-size: .6rem;
}

.btn.disabled-max-min
{
    cursor: default; 

    background-color: rgba(0, 0, 0, .5) !important;
}

/* Number Type Input Box Scss for - Remove arrow on hover */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button
{
    -webkit-appearance: none;
}

/* Number Type Input Box Scss for Touchspin - Remove arrow for firefox */
.bootstrap-touchspin.input-group input[type=number]
{
    -moz-appearance: textfield;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .form-label-group label
    {
        display: none;
    }
    select.form-control:not([multiple='multiple'])
    {
        background: none;
    }
    .custom-file-label::after
    {
        padding: 1.34rem .7rem 1.25rem;
    }
}

.picker__input.form-control
{
    background-color: #fff;
}

.input-group .form-control
{
    height: auto;
}

.input-group .input-group-prepend .dropdown-toggle::after,
.input-group .input-group-append .dropdown-toggle::after
{
    left: 0;
}

.input-group .input-group-prepend .vs-checkbox-con,
.input-group .input-group-prepend .vs-checkbox,
.input-group .input-group-prepend .vs-radio-con,
.input-group .input-group-prepend .vs-radio,
.input-group .input-group-append .vs-checkbox-con,
.input-group .input-group-append .vs-checkbox,
.input-group .input-group-append .vs-radio-con,
.input-group .input-group-append .vs-radio
{
    margin: 0;
}

.input-group.input-group-lg .form-control::-webkit-input-placeholder
{
    font-size: 1.25rem;
}

.input-group.input-group-lg .form-control:-moz-placeholder
{
    font-size: 1.25rem;
}

.input-group.input-group-lg .form-control::-moz-placeholder
{
    font-size: 1.25rem;
}

.input-group.input-group-lg .form-control:-ms-input-placeholder
{
    font-size: 1.25rem;
}

.input-group.input-group-lg .form-control::placeholder
{
    font-size: 1.25rem;
}

.input-group.input-group-lg .input-group-prepend .btn,
.input-group.input-group-lg .input-group-append .btn
{
    font-size: 1.3rem; 
    line-height: 1.3;
}

.input-group.input-group-sm .form-control::-webkit-input-placeholder
{
    font-size: .7rem;
}

.input-group.input-group-sm .form-control:-moz-placeholder
{
    font-size: .7rem;
}

.input-group.input-group-sm .form-control::-moz-placeholder
{
    font-size: .7rem;
}

.input-group.input-group-sm .form-control:-ms-input-placeholder
{
    font-size: .7rem;
}

.input-group.input-group-sm .form-control::placeholder
{
    font-size: .7rem;
}

.btn:disabled
{
    cursor: default;
}

.btn.btn-white
{
    color: #626262;
}
.btn.btn-white:hover,
.btn.btn-white:focus,
.btn.btn-white:active
{
    color: #626262 !important;
}

.btn[class*='bg-gradient-']:hover
{
    -webkit-box-shadow: 0 8px 25px -8px #aaa;
            box-shadow: 0 8px 25px -8px #aaa;
}

.btn.btn-secondary
{
    color: #fff;
}
.btn.btn-secondary:hover
{
    -webkit-box-shadow: 0 8px 25px -8px #b8c2cc;
            box-shadow: 0 8px 25px -8px #b8c2cc;
}

.btn.btn-dark:hover
{
    -webkit-box-shadow: 0 8px 25px -8px #1e1e1e;
            box-shadow: 0 8px 25px -8px #1e1e1e;
}

.btn.btn-light:hover
{
    -webkit-box-shadow: 0 8px 25px -8px #babfc7;
            box-shadow: 0 8px 25px -8px #babfc7;
}

.btn.btn-outline-secondary,
.btn.btn-outline-dark,
.btn.btn-outline-light
{
    border: 1px solid;
}

.btn.btn-flat-secondary:hover
{
    background-color: rgba(184, 194, 204, .1);
}

.btn.btn-flat-secondary:active,
.btn.btn-flat-secondary:focus
{
    background: #b8c2cc;
}

.btn.btn-flat-dark:hover
{
    background-color: rgba(30, 30, 30, .1);
}

.btn.btn-flat-dark:active,
.btn.btn-flat-dark:focus
{
    color: #fff; 
    background: #1e1e1e;
}

.btn.btn-flat-light:hover
{
    background-color: rgba(186, 191, 199, .1);
}

.btn.btn-flat-light:active,
.btn.btn-flat-light:focus
{
    background: #babfc7;
}

.btn[class*='btn-outline-']
{
    padding-top: -webkit-calc(.9rem - 1px);
    padding-top:    -moz-calc(.9rem - 1px);
    padding-top:         calc(.9rem - 1px);
    padding-bottom: -webkit-calc(.9rem - 1px);
    padding-bottom:    -moz-calc(.9rem - 1px);
    padding-bottom:         calc(.9rem - 1px);
}
.btn[class*='btn-outline-'].btn-lg
{
    padding-top: -webkit-calc(1rem - 1px);
    padding-top:    -moz-calc(1rem - 1px);
    padding-top:         calc(1rem - 1px);
    padding-bottom: -webkit-calc(1rem - 1px);
    padding-bottom:    -moz-calc(1rem - 1px);
    padding-bottom:         calc(1rem - 1px);
}
.btn[class*='btn-outline-'].btn-sm
{
    padding-top: -webkit-calc(.5rem - 1px);
    padding-top:    -moz-calc(.5rem - 1px);
    padding-top:         calc(.5rem - 1px);
    padding-bottom: -webkit-calc(.5rem - 1px);
    padding-bottom:    -moz-calc(.5rem - 1px);
    padding-bottom:         calc(.5rem - 1px);
}

.btn.btn-relief-secondary
{
    color: #fff; 
    background-color: #b8c2cc;
    -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .2);
            box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .2);
}
.btn.btn-relief-secondary:hover
{
    color: #fff;
}
.btn.btn-relief-secondary:active,
.btn.btn-relief-secondary:focus
{
    -webkit-transform: translateY(3px);
       -moz-transform: translateY(3px);
        -ms-transform: translateY(3px);
         -o-transform: translateY(3px);
            transform: translateY(3px); 

    outline: none;
}

.btn.btn-relief-light
{
    color: #fff; 
    background-color: #babfc7;
    -webkit-box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .2);
            box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, .2);
}
.btn.btn-relief-light:hover
{
    color: #fff;
}
.btn.btn-relief-light:active,
.btn.btn-relief-light:focus
{
    -webkit-transform: translateY(3px);
       -moz-transform: translateY(3px);
        -ms-transform: translateY(3px);
         -o-transform: translateY(3px);
            transform: translateY(3px); 

    outline: none;
}

.btn.btn-relief-dark
{
    color: #fff; 
    background-color: #1e1e1e;
    -webkit-box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, .1);
            box-shadow: inset 0 -3px 0 0 rgba(255, 255, 255, .1);
}
.btn.btn-relief-dark:hover
{
    color: #fff;
}
.btn.btn-relief-dark:active,
.btn.btn-relief-dark:focus
{
    -webkit-transform: translateY(3px);
       -moz-transform: translateY(3px);
        -ms-transform: translateY(3px);
         -o-transform: translateY(3px);
            transform: translateY(3px); 

    outline: none;
}

.btn.btn-icon
{
    padding: .715rem .736rem;
}

.btn.btn-sm.btn-icon
{
    padding: .5rem;
}

.btn.btn-lg.btn-icon
{
    padding: 1rem;
}

.btn.btn-square
{
    border-radius: 0;
}

.btn.btn-round
{
    border-radius: 2rem;
}

.btn:focus,
.btn.focus,
.btn:active,
.btn.active
{
    outline: none;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}

.btn.btn-icon i
{
    font-size: 1.1rem;
}

.btn.btn-outline-white:hover,
.btn-white:hover
{
    color: #22292f !important;
}

button,
html [type='button'],
[type='reset'],
[type='submit']
{
    cursor: pointer;
}

.waves-button-input
{
    padding: unset;
}

.collapse-icon .card-header
{
    position: relative;
}

.collapse-icon [data-toggle='collapse']:after
{
    font-family: 'feather';
    font-size: .9rem; 

    position: absolute;
    top: 48%;
    right: 20px;

    margin-top: -8px;

    content: '\e845';
    -webkit-transition: all 300ms linear 0s;
       -moz-transition: all 300ms linear 0s;
         -o-transition: all 300ms linear 0s;
            transition: all 300ms linear 0s;
}

.collapse-icon [data-toggle='collapse']:before
{
    font-family: 'feather';
    font-size: .9rem; 

    position: absolute;
    top: 48%;
    right: 20px;

    margin-top: -8px;

    content: '\e842';
    -webkit-transition: all 300ms linear 0s;
       -moz-transition: all 300ms linear 0s;
         -o-transition: all 300ms linear 0s;
            transition: all 300ms linear 0s;
}

.collapse-icon.left a
{
    padding-left: 28px;
}
.collapse-icon.left a:before,
.collapse-icon.left a:after
{
    right: auto; 
    left: 20px;
}

.collapse-icon [aria-expanded='true']:before
{
    opacity: 0;
}

.collapse-icon [aria-expanded='false']:after
{
    opacity: 0;
}

.collapse-icon.accordion-icon-rotate [aria-expanded='true']:before
{
    -webkit-transform: rotate(180deg);
       -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
         -o-transform: rotate(180deg);
            transform: rotate(180deg);
}

.collapse-icon.accordion-icon-rotate [aria-expanded='false']:after
{
    -webkit-transform: rotate(-180deg);
       -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
         -o-transform: rotate(-180deg);
            transform: rotate(-180deg);
}

.dropdown.show .btn-outline-warning.dropdown-toggle,
.dropdown.show .btn-outline-light.dropdown-toggle
{
    color: #fff;
}

.dropdown .btn:not(.btn-sm):not(.btn-lg),
.dropdown .btn:not(.btn-sm):not(.btn-lg).dropdown-toggle
{
    padding: .85rem 1.5rem;
}
.dropdown .btn:not(.btn-sm):not(.btn-lg).btn-round,
.dropdown .btn:not(.btn-sm):not(.btn-lg).dropdown-toggle.btn-round
{
    border-radius: 2rem;
}

.dropdown .btn.dropdown-toggle.dropdown-toggle-split
{
    padding: .85rem 1rem;

    border-left-color: rgba(255, 255, 255, .2) !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.dropdown [class*='btn-outline-'].dropdown-toggle.dropdown-toggle-split
{
    border-left: 0 !important;
}
.dropdown [class*='btn-outline-'].dropdown-toggle.dropdown-toggle-split:active
{
    color: #fff;
}

.dropdown .dropdown-menu
{
    min-width: 8rem; 

    -webkit-transform: scale(1, 0);
       -moz-transform: scale(1, 0);
        -ms-transform: scale(1, 0);
         -o-transform: scale(1, 0);
            transform: scale(1, 0);

    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 25px rgba(0, 0, 0, .1);
            box-shadow: 0 5px 25px rgba(0, 0, 0, .1);
}
.dropdown .dropdown-menu::before
{
    position: absolute;
    z-index: 10;
    top: -1px;
    left: 1.2rem;

    display: block;

    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box; 
    width: .75rem;
    height: .75rem;

    content: '';
    -webkit-transform: rotate(45deg) translate(-7px);
       -moz-transform: rotate(45deg) translate(-7px);
        -ms-transform: rotate(45deg) translate(-7px);
         -o-transform: rotate(45deg) translate(-7px);
            transform: rotate(45deg) translate(-7px);

    border-top: 1px solid rgba(0, 0, 0, .1);
    border-left: 1px solid rgba(0, 0, 0, .1);
    background: #fff;
}
.dropdown .dropdown-menu.dropdown-menu-right::before
{
    right: .6rem;
    left: auto;
}
.dropdown .dropdown-menu .dropdown-header
{
    margin-top: .714rem; 
    padding: .714rem 1.428rem;
}
.dropdown .dropdown-menu .dropdown-item
{
    width: auto;
    padding: .5rem 1rem;

    cursor: pointer; 

    color: #626262;
}
.dropdown .dropdown-menu .dropdown-item:hover
{
    color: #46003A; 
    background-color: #f8f8f8;
}
.dropdown .dropdown-menu .dropdown-item.active
{
    color: #fff;
}
.dropdown .dropdown-menu .dropdown-item:active
{
    color: #626262; 
    background-color: transparent;
}

.dropdown i
{
    margin-right: .5rem;
}

.dropdown.no-arrow .dropdown-toggle:after
{
    display: none;
}

.dropdown.no-arrow .dropdown-toggle i
{
    margin-right: 0;
}

.dropdown,
.dropup,
.dropright,
.dropleft
{
    position: relative;
}
.dropdown .dropdown-menu .dropdown-item,
.dropup .dropdown-menu .dropdown-item,
.dropright .dropdown-menu .dropdown-item,
.dropleft .dropdown-menu .dropdown-item
{
    padding: .714rem 1.428rem;
}

.show > .dropdown-menu
{
    display: block; 

    -webkit-transform: scale(1, 1);
       -moz-transform: scale(1, 1);
        -ms-transform: scale(1, 1);
         -o-transform: scale(1, 1);
            transform: scale(1, 1);

    opacity: 1;
}

.dropdown-toggle::after
{
    font-family: 'feather';

    position: relative;
    top: 1px;
    right: 0;
    left: .714rem;

    margin: 0;
    padding: 0;

    content: '\e842' !important;
    vertical-align: 0; 

    border: none !important;
}

.dropdown-toggle.dropdown-toggle-split
{
    border-left: 1px solid rgba(255, 255, 255, .2);
}
.dropdown-toggle.dropdown-toggle-split:after
{
    left: 0;
}

.dropdown-toggle.nav-hide-arrow::after
{
    display: none;
}

.dropdown-toggle:focus
{
    outline: 0;
}

.navbar-horizontal .dropdown-submenu .dropdown-toggle::after
{
    top: -9px !important;
    right: 1px; 

    margin-top: 1.25rem;
}

/* .dropdown-menu-right {
  left: auto !important; // Input group with dropdown issue for dropdown-menu-right
  right: 0;
} */
.btn-secondary ~ .dropdown-menu .dropdown-item.active,
.btn-secondary ~ .dropdown-menu .dropdown-item:hover
{
    background-color: #ccc;
}

.dropup
{
    position: relative;
}
.dropup .btn,
.dropup .btn.dropdown-toggle
{
    padding: .85rem 1.5rem;
}
.dropup .btn.dropdown-toggle.dropdown-toggle-split
{
    padding: .85rem 1rem;

    border-color: rgba(255, 255, 255, .2) !important;
}
.dropup .dropdown-toggle::after
{
    content: '\e845' !important;
    vertical-align: .05rem;
}
.dropup .dropdown-menu::before
{
    position: absolute;
    z-index: 10;
    bottom: -.714rem;
    left: 1.214rem;

    display: block;

    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box; 
    width: .714rem;
    height: .714rem;

    content: '';
    -webkit-transform: rotate(45deg) translate(-7px);
       -moz-transform: rotate(45deg) translate(-7px);
        -ms-transform: rotate(45deg) translate(-7px);
         -o-transform: rotate(45deg) translate(-7px);
            transform: rotate(45deg) translate(-7px);

    border-right: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background: #fff;
}
.dropup .dropdown-menu.dropdown-menu-right::before
{
    right: .714rem; 
    left: auto;
}
.dropup .dropdown-submenu .dropdown-menu
{
    top: 0; 
    bottom: auto;
}

.dropleft .btn,
.dropleft .btn.dropdown-toggle
{
    padding: .85rem 1.5rem;
}

.dropleft .btn.dropdown-toggle.dropdown-toggle-split
{
    padding: .85rem 1rem;
}

.dropleft .dropdown-toggle::before
{
    font-family: 'feather';

    content: '\e843' !important; 

    border: none !important;
}

.dropleft .dropdown-menu::before
{
    position: absolute;
    z-index: 10;
    top: 1.285rem;
    right: 0;

    display: block;

    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box; 
    width: .714rem;
    height: .714rem;

    content: '';
    -webkit-transform: rotate(130deg) translate(-8px);
       -moz-transform: rotate(130deg) translate(-8px);
        -ms-transform: rotate(130deg) translate(-8px);
         -o-transform: rotate(130deg) translate(-8px);
            transform: rotate(130deg) translate(-8px);

    border-top: 1px solid rgba(0, 0, 0, .1);
    border-left: 1px solid rgba(0, 0, 0, .1);
    background: #fff;
}

.dropright .btn,
.dropright .btn.dropdown-toggle
{
    padding: .85rem 1.5rem;
}

.dropright .btn.dropdown-toggle.dropdown-toggle-split
{
    padding: .85rem 1rem;
}

.dropright .dropdown-toggle::after
{
    font-family: 'feather';

    content: '\e844' !important; 

    border: none !important;
}

.dropright .dropdown-menu::before
{
    position: absolute;
    z-index: 10;
    top: 1.285rem;
    left: -.9rem;

    display: block;

    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box; 
    width: .714rem;
    height: .714rem;

    content: '';
    -webkit-transform: rotate(140deg) translate(-9px);
       -moz-transform: rotate(140deg) translate(-9px);
        -ms-transform: rotate(140deg) translate(-9px);
         -o-transform: rotate(140deg) translate(-9px);
            transform: rotate(140deg) translate(-9px);

    border-right: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background: #fff;
}

.dropdown.dropdown-icon-wrapper .dropdown-toggle:after,
.dropup.dropdown-icon-wrapper .dropdown-toggle:after
{
    display: none;
}

.dropdown.dropdown-icon-wrapper .dropdown-menu,
.dropup.dropdown-icon-wrapper .dropdown-menu
{
    min-width: auto;
}
.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item,
.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item
{
    padding: .5rem 1.1rem;

    cursor: pointer;
}
.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item i,
.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item i
{
    font-size: 1.3rem;

    color: #626262;
}
.dropdown.dropdown-icon-wrapper .dropdown-menu .dropdown-item:hover,
.dropup.dropdown-icon-wrapper .dropdown-menu .dropdown-item:hover
{
    color: #fff;
}

.btn.btn-white ~ .dropdown-menu .dropdown-item:not(.acitve):hover
{
    color: #626262 !important;
}

.btn.btn-white ~ .dropdown-menu .dropdown-item.active
{
    color: #626262;
}

/* ===============================================================================================
    File Name: navbar.scss
    Description: Contain header navigation bar, vertical main navigation bar and
    horiznotal main navigation bar related SCSS.
    ----------------------------------------------------------------------------------------------
    Item Name: Vuesax HTML Admin Template
    Version: 1.1
    Author: PIXINVENT
    Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/
.navbar-dark .hamburger-inner,
.navbar-dark .hamburger-inner::before,
.navbar-dark .hamburger-inner::after
{
    background-color: #fff;
}

.header-navbar-shadow
{
    display: none;
}

.navbar-floating .header-navbar-shadow
{
    position: fixed;
    z-index: 11; 
    top: 0;

    display: block;

    width: 100%;
    height: 102px;
    padding-top: 2.2rem;

    background: -webkit-gradient(linear, left top, left bottom, color-stop(44%, rgba(248, 248, 248, .95)), color-stop(73%, rgba(248, 248, 248, .46)), to(rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(top, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
    background:    -moz-linear-gradient(top, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
    background:      -o-linear-gradient(top, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
    background:         linear-gradient(180deg, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
    background-repeat: repeat;
}

.header-navbar
{
    font-family: 'Montserrat', Helvetica, Arial, serif;

    z-index: 1000;

    min-height: 4.5rem;
    padding: 0;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all;

    background: -webkit-gradient(linear, left top, left bottom, color-stop(44%, rgba(248, 248, 248, .95)), color-stop(73%, rgba(248, 248, 248, .46)), to(rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(top, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
    background:    -moz-linear-gradient(top, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
    background:      -o-linear-gradient(top, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
    background:         linear-gradient(180deg, rgba(248, 248, 248, .95) 44%, rgba(248, 248, 248, .46) 73%, rgba(255, 255, 255, 0));
    /* For sm screen*/
}
.header-navbar.fixed-top
{
    left: 260px;
}
.header-navbar[class*='bg-'] .navbar-nav .nav-item a
{
    color: #fff !important;
}
.header-navbar[class*='bg-'] .navbar-nav .nav-item a i,
.header-navbar[class*='bg-'] .navbar-nav .nav-item a span
{
    color: #fff !important;
}
.header-navbar[class*='bg-'] .navbar-nav .search-input .input,
.header-navbar[class*='bg-'] .navbar-nav .search-input .search-list .auto-suggestion span
{
    color: #626262 !important;
}
.header-navbar.floating-nav
{
    position: fixed;
    z-index: 11;
    right: 0; 

    width: -webkit-calc(100% - -webkit-calc(2.2rem * 2) - 260px);
    width:    -moz-calc(100% - -moz-calc(2.2rem * 2) - 260px);
    width:         calc(100% - calc(2.2rem * 2) - 260px);
    margin: 1.3rem 2.2rem 0;

    border-radius: .5rem;
}
.header-navbar.navbar-static-top
{
    background: transparent;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}
.header-navbar .navbar-wrapper
{
    width: 100%;
}
.header-navbar.navbar-border
{
    border-bottom: 1px solid #e4e7ed;
}
.header-navbar.navbar-dark.navbar-border
{
    border-bottom: 1px solid #8596b5;
}
.header-navbar.navbar-shadow
{
    -webkit-box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);
}
.header-navbar .nav-link.dropdown-toggle::after
{
    display: none;
}
.header-navbar .navbar-container ul.nav li.dropdown-language
{
    padding-right: 0;
}
.header-navbar .navbar-container ul.nav li.dropdown-language .selected-language
{
    font-size: .97rem;
    font-weight: 500; 

    letter-spacing: -.25px;
}
.header-navbar .navbar-container
{
    margin-left: 0; 
    padding-left: 1rem;

    -webkit-transition: 300ms ease all;
       -moz-transition: 300ms ease all;
         -o-transition: 300ms ease all;
            transition: 300ms ease all;

    background: inherit;
}
.header-navbar .navbar-container .bookmark-wrapper ul.nav li > a.nav-link
{
    padding: 1.4rem .5rem 1.35rem;
}
.header-navbar .navbar-container .bookmark-wrapper ul.nav li > a.nav-link i:hover
{
    color: #46003A;
}
.header-navbar .navbar-container .bookmark-input
{
    z-index: 1;
}
.header-navbar .navbar-container ul.nav li.dropdown .dropdown-menu
{
    top: 48px;
}
.header-navbar .navbar-container ul.nav li .badge
{
    padding: .42em .6em .25rem;
}
.header-navbar .navbar-container ul.nav li .badge.badge-up
{
    position: absolute;
    top: 12px;
    right: -2px;
}
.header-navbar .navbar-container ul.nav li.dropdown-language > a.nav-link
{
    padding: 1.75rem .5rem;
}
.header-navbar .navbar-container ul.nav li > a.nav-link
{
    padding: 1.6rem .5rem 1.35rem 1rem; 

    color: #626262;
}
.header-navbar .navbar-container ul.nav li > a.nav-link i.flag-icon
{
    margin-right: .5rem;
}
.header-navbar .navbar-container ul.nav li.dropdown-user .dropdown-menu-right
{
    right: 12px;
    left: auto;

    padding: .5rem;
}
.header-navbar .navbar-container ul.nav li.dropdown-user .dropdown-menu-right .dropdown-item
{
    padding: 10px;
}
.header-navbar .navbar-container ul.nav li.dropdown-user .dropdown-menu-right .dropdown-item:hover,
.header-navbar .navbar-container ul.nav li.dropdown-user .dropdown-menu-right .dropdown-item:active
{
    color: #fff; 
    background: #46003A;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding: .7rem 1rem;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-name
{
    display: inline-block;

    margin-bottom: .435rem;
    margin-left: .2rem;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-status
{
    font-size: smaller;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link img
{
    -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .12), 0 2px 4px 0 rgba(0, 0, 0, .08);
}
.header-navbar .navbar-container ul.nav li a.menu-toggle
{
    padding: 1.34rem .22rem 1.35rem 0;
}
.header-navbar .navbar-container ul.nav li a.menu-toggle i
{
    font-size: 1.75rem;
}
.header-navbar .navbar-container ul.nav li a.nav-link-label
{
    padding: 1.6rem 0rem 1.35rem .8rem;
}
.header-navbar .navbar-container ul.nav li a.dropdown-user-link .user-nav
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
    float: left;
            flex-direction: column;

    margin-right: .8rem; 

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
       -moz-box-align: end;
    -ms-flex-align: end;
            align-items: flex-end;
}
.header-navbar .navbar-container ul.nav li div.input-group
{
    padding: .7rem 1rem;
}
.header-navbar .navbar-container ul.nav li i.ficon
{
    font-size: 1.5rem;

    color: #626262;
}
.header-navbar .navbar-container ul.nav li i.ficon:hover
{
    color: #46003A;
}
.header-navbar .navbar-container ul.nav li .media-list
{
    max-height: 18.2rem;
}
.header-navbar .navbar-container ul.nav li .scrollable-container
{
    position: relative;
}
.header-navbar .navbar-container .dropdown-menu-media
{
    width: 26rem;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list .media
{
    padding: 1rem;

    border: none;
    border-bottom: 1px solid #dae1e7;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list .media:hover
{
    background: #f8f8f8;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list .media .media-meta
{
    color: #626262;
}
.header-navbar .navbar-container .dropdown-menu-media .media-list a:last-of-type .media
{
    border-bottom: none;
}
.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-header
{
    border-bottom: 1px solid #dae1e7;
}
.header-navbar .navbar-container .dropdown-menu-media .dropdown-menu-footer a
{
    padding: .3rem;

    border-top: 1px solid #dae1e7;
}
.header-navbar.navbar-without-menu .navbar-container
{
    margin-left: 0;
}
.header-navbar .nav-item + .nav-item
{
    margin-left: 0rem;
}
@media (max-width: 767.98px)
{
    .header-navbar .navbar-header
    {
        position: relative; 

        width: 100% !important;
        padding: .5rem 1rem;
    }
    .header-navbar .navbar-header .menu-toggle
    {
        position: relative; 
        top: 2px;
    }
    .header-navbar .navbar-header .open-navbar-container i
    {
        font-size: 1.8rem;
    }
    .header-navbar .navbar-header .navbar-brand
    {
        position: absolute;
        top: 0;
        left: 50%;

        -webkit-transform: translate(-50%, 0);
           -moz-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
             -o-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
    }
    .header-navbar.navbar-with-menu .navbar-container
    {
        display: table;

        width: 100%;
        height: auto; 
        margin: 0;
    }
}

/* Modern menu For md screen*/
@media (max-width: 991.98px)
{
    [data-menu='vertical-menu-modern'] .header-navbar .navbar-header
    {
        position: relative; 

        width: 100% !important;
        padding: .5rem 1rem;
    }
    [data-menu='vertical-menu-modern'] .header-navbar .navbar-header .menu-toggle
    {
        position: relative; 
        top: 2px;
    }
    [data-menu='vertical-menu-modern'] .header-navbar .navbar-header .open-navbar-container i
    {
        font-size: 1.8rem;
    }
    [data-menu='vertical-menu-modern'] .header-navbar .navbar-header .navbar-brand
    {
        position: absolute;
        top: 0;
        left: 50%;

        -webkit-transform: translate(-50%, 0);
           -moz-transform: translate(-50%, 0);
            -ms-transform: translate(-50%, 0);
             -o-transform: translate(-50%, 0);
                transform: translate(-50%, 0);
    }
    [data-menu='vertical-menu-modern'] .header-navbar.navbar-with-menu .navbar-container
    {
        display: table;

        width: 100%;
        height: auto; 
        margin: 0;
    }
    [data-menu='vertical-menu-modern'] .navbar-dark .navbar-header .navbar-nav .nav-link,
    [data-menu='vertical-menu-modern'] .navbar-semi-dark .navbar-header .navbar-nav .nav-link
    {
        color: #fff;
    }
    [data-menu='vertical-menu-modern'] .navbar-dark .navbar-container .navbar-nav .nav-link,
    [data-menu='vertical-menu-modern'] .navbar-semi-dark .navbar-container .navbar-nav .nav-link
    {
        color: #b8c2cc;
    }
    [data-menu='vertical-menu-modern'] .navbar-light .navbar-header .navbar-nav .nav-link
    {
        color: #b8c2cc;
    }
}

.navbar-light
{
    background: #fff;
}
.navbar-light.navbar-horizontal
{
    background: #fff;
}
.navbar-light .navbar-nav li
{
    line-height: 1;
}
.navbar-light .navbar-nav .active.nav-link
{
    background-color: rgba(0, 0, 0, .03);
}
.navbar-light .navbar-nav .disabled.nav-link
{
    color: #b8c2cc;
}

.navbar-dark
{
    background: #b8c2cc;
}
.navbar-dark .navbar-brand
{
    color: #fff !important;
}
.navbar-dark.navbar-horizontal
{
    background: #b8c2cc;
}
.navbar-dark .nav-search .form-control,
.navbar-dark .nav-search .btn-secondary
{
    color: #fff;
    background: #b8c2cc;
}
.navbar-dark .navbar-nav li
{
    line-height: 1;
}
.navbar-dark .navbar-nav .active.nav-link
{
    background-color: rgba(255, 255, 255, .05);
}
.navbar-dark .navbar-nav .disabled.nav-link
{
    color: #dae1e7;
}

.navbar-semi-dark
{
    background: #fff;
}
.navbar-semi-dark .navbar-header
{
    background: #b8c2cc;
}
.navbar-semi-dark .navbar-header .brand-text
{
    color: #fff;
}
.navbar-semi-dark .navbar-nav li
{
    line-height: 1;
}
.navbar-semi-dark .navbar-nav .nav-link
{
    color: #b8c2cc;
}
.navbar-semi-dark .navbar-nav .active.nav-link
{
    background-color: rgba(0, 0, 0, .03);
}
.navbar-semi-dark .navbar-nav .disabled.nav-link
{
    color: #b8c2cc;
}

.navbar-semi-light
{
    background: #b8c2cc;
}
.navbar-semi-light .navbar-header
{
    background: #fff;
}
.navbar-semi-light .navbar-header .brand-text
{
    color: #b8c2cc;
}
.navbar-semi-light .navbar-nav li
{
    line-height: 1;
}
.navbar-semi-light .navbar-nav .nav-link
{
    color: #fff;
}
.navbar-semi-light .navbar-nav .active.nav-link
{
    background-color: rgba(0, 0, 0, .03);
}
.navbar-semi-light .navbar-nav .disabled.nav-link
{
    color: #b8c2cc;
}

@-moz-document url-prefix()
{
    ul li a .children-in
    {
        position: relative;
        top: -14px; 
        right: 3px;
    }
}

.navbar-menu-icon span
{
    position: absolute;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    -webkit-transition: .25s ease-in-out;
       -moz-transition: .25s ease-in-out;
         -o-transition: .25s ease-in-out;
            transition: .25s ease-in-out; 
    -webkit-transform: rotate(0deg);
       -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
         -o-transform: rotate(0deg);
            transform: rotate(0deg);

    opacity: 1;
    border-radius: 9px;
    background: #b8c2cc;
}

.navbar-menu-icon span:nth-child(1)
{
    top: 0;
}

.navbar-menu-icon span:nth-child(2)
{
    top: 10px;
}

.navbar-menu-icon span:nth-child(3)
{
    top: 20px;
}

.navbar-menu-icon.show span:nth-child(1)
{
    top: 10px;

    -webkit-transform: rotate(135deg);
       -moz-transform: rotate(135deg);
        -ms-transform: rotate(135deg);
         -o-transform: rotate(135deg);
            transform: rotate(135deg);
}

.navbar-menu-icon.show span:nth-child(2)
{
    left: -60px; 

    opacity: 0;
}

.navbar-menu-icon.show span:nth-child(3)
{
    top: 10px;

    -webkit-transform: rotate(-135deg);
       -moz-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
         -o-transform: rotate(-135deg);
            transform: rotate(-135deg);
}

@media (max-width: 767.98px)
{
    .header-navbar .navbar-nav .show
    {
        position: static;
    }
    .header-navbar .navbar-nav .open-navbar-container
    {
        padding-top: .625rem;
    }
    .header-navbar .navbar-container .show .dropdown-menu
    {
        right: 0;
        left: 0 !important;

        float: none;
        overflow-x: hidden; 

        width: auto;
        max-height: 400px;
        margin-top: 0;
    }
    .header-navbar .navbar-container ul.nav li.nav-item i
    {
        margin-right: .2rem;
    }
    .header-navbar .navbar-container ul.nav li a.dropdown-user-link
    {
        padding: .8rem .6rem;
    }
    .header-navbar .navbar-container ul.nav li .dropdown-toggle::after
    {
        margin-right: 0;
        margin-left: -2px;
    }
    .navbar-dark .hamburger-inner,
    .navbar-dark .hamburger-inner::before,
    .navbar-dark .hamburger-inner::after,
    .navbar-semi-dark .hamburger-inner,
    .navbar-semi-dark .hamburger-inner::before,
    .navbar-semi-dark .hamburger-inner::after
    {
        background-color: #fff;
    }
    .navbar-dark .navbar-header .navbar-nav .nav-link,
    .navbar-semi-dark .navbar-header .navbar-nav .nav-link
    {
        color: #fff;
    }
    .navbar-dark .navbar-container .navbar-nav .nav-link,
    .navbar-semi-dark .navbar-container .navbar-nav .nav-link
    {
        color: #b8c2cc;
    }
    .navbar-light .navbar-header .navbar-nav .nav-link,
    .navbar-semi-light .navbar-header .navbar-nav .nav-link
    {
        color: #b8c2cc;
    }
    .navbar-light .navbar-container .navbar-nav .nav-link,
    .navbar-semi-light .navbar-container .navbar-nav .nav-link
    {
        color: #b8c2cc;
    }
}

.headroom
{
    -webkit-transition: -webkit-transform 200ms linear;
       -moz-transition:         transform 200ms linear, -moz-transform 200ms linear;
         -o-transition:      -o-transform 200ms linear;
            transition: -webkit-transform 200ms linear;
            transition:         transform 200ms linear;
            transition:         transform 200ms linear, -webkit-transform 200ms linear, -moz-transform 200ms linear, -o-transform 200ms linear; 

    will-change: transform;
}

.headroom--pinned-top
{
    -webkit-transform: translateY(0%);
       -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
         -o-transform: translateY(0%);
            transform: translateY(0%);
}

.headroom--unpinned-top
{
    -webkit-transform: translateY(-100%);
       -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
         -o-transform: translateY(-100%);
            transform: translateY(-100%);
}

.headroom--pinned-bottom
{
    -webkit-transform: translateY(0%);
       -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
         -o-transform: translateY(0%);
            transform: translateY(0%);
}

.headroom--unpinned-bottom
{
    -webkit-transform: translateY(100%);
       -moz-transform: translateY(100%);
        -ms-transform: translateY(100%);
         -o-transform: translateY(100%);
            transform: translateY(100%);
}

@media (max-width: 575.98px)
{
    .header-navbar .navbar-container ul.nav li .selected-language
    {
        display: none;
    }
}

body[data-col='1-column'] .header-navbar.floating-nav
{
    width: -webkit-calc(100% - -webkit-calc(2.2rem * 2));
    width:    -moz-calc(100% - -moz-calc(2.2rem * 2));
    width:         calc(100% - calc(2.2rem * 2));
}

@media (min-width: 1200px) and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    body:not([data-col='1-columns']) .header-navbar.floating-nav
    {
        width: -webkit-calc(100% - 60px - 260px);
        width:    -moz-calc(100% - 60px - 260px);
        width:         calc(100% - 60px - 260px);
    }
}

@media (max-width: 1199.98px)
{
    .header-navbar .navbar-container ul.nav li.dropdown-language > a.nav-link
    {
        padding: 1.75rem .5rem;
    }
    .header-navbar .navbar-container ul.nav li a.dropdown-user-link
    {
        padding: .7rem 1.23rem;
    }
    .header-navbar .navbar-container ul.nav li a.nav-link-search
    {
        padding: 1.6rem 0 1.35rem 1rem;
    }
    .header-navbar .navbar-container ul.nav li .dropdown-toggle::after
    {
        margin-right: 0;
        margin-left: -2px;
    }
}

@media (max-width: 767px)
{
    #navbar-mobile.navbar-collapse .navbar-nav
    {
        margin: 0;

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
    }
    #navbar-mobile.navbar-collapse .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    #navbar-mobile.navbar-collapse .navbar-nav .nav-item
    {
        float: left;
    }
}

@media (max-width: 991.98px)
{
    #navbar-mobile.navbar-collapse .navbar-nav
    {
        margin: 0;

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-flow: row wrap;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
    }
    #navbar-mobile.navbar-collapse .navbar-nav .dropdown-menu
    {
        position: absolute;
    }
    #navbar-mobile.navbar-collapse .navbar-nav .nav-item
    {
        float: left;
    }
}

/* ----------- iPhone 5, 5S  iPhone 6----------- */
/* Landscape */
@media only screen and (min-device-width: 26.78em) and (max-device-width: 47.64em) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape)
{
    .header-navbar .navbar-container .show .dropdown-menu
    {
        max-height: 180px;
    }
}

/* ----------- iPhone 6+ ----------- */
/* Landscape */
@media only screen and (min-device-width: 29.57em) and (max-device-width: 52.57em) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)
{
    .header-navbar .navbar-container .show .dropdown-menu
    {
        max-height: 280px;
    }
}

/*----------------------------- For Navbar Component -------------------------*/
#navbar-component .navbar
{
    z-index: auto;
}
#navbar-component .navbar.floating-nav
{
    border-radius: .5rem;
}
#navbar-component .navbar .navbar-nav .nav-item a
{
    color: inherit !important;
}
#navbar-component .navbar.header-navbar.navbar-dark .navbar-nav .nav-link
{
    color: #fff;
}
#navbar-component .navbar.header-navbar.navbar-dark .navbar-nav .nav-link i
{
    color: #fff;
}
#navbar-component .navbar .navbar-nav .dropdown-language.show,
#navbar-component .navbar .navbar-nav .dropdown-notification.show,
#navbar-component .navbar .navbar-nav .dropdown-user.show
{
    position: relative;
}
#navbar-component .navbar .navbar-nav .dropdown-language .dropdown-menu.show,
#navbar-component .navbar .navbar-nav .dropdown-notification .dropdown-menu.show,
#navbar-component .navbar .navbar-nav .dropdown-user .dropdown-menu.show
{
    position: absolute;
    right: 0;
    left: auto !important;
}
#navbar-component .navbar.navbar-dark .navbar-toggler,
#navbar-component .navbar.navbar-light .navbar-toggler
{
    font-size: 1.2rem;
}
#navbar-component .navbar.navbar-dark .navbar-toggler:focus,
#navbar-component .navbar.navbar-light .navbar-toggler:focus
{
    outline: 0;
}

#navbar-component #navbar-collapsed .navbar
{
    border-radius: 0;
}

@media (min-width: 992px)
{
    #navbar-component .navbar .navbar-container
    {
        background: inherit;
    }
    #navbar-component .nav .dropdown-language
    {
        float: right;
    }
}

@media (max-width: 992px)
{
    #navbar-component .navbar .navbar-container
    {
        background: inherit;
    }
    #navbar-component .navbar .navbar-container .navbar-nav
    {
                flex-direction: row; 

        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
           -moz-box-orient: horizontal;
           -moz-box-direction: normal;
            -ms-flex-direction: row;
    }
}

@media (max-width: 1200px)
{
    .header-navbar.fixed-top
    {
        left: 0;
    }
}

@media (max-width: 768px)
{
    #navbar-component .navbar .navbar-nav .dropdown-notification.show
    {
        position: static;
    }
}

@media (max-width: 575.98px)
{
    .header-navbar.floating-nav
    {
        width: -webkit-calc(100% - 2.4rem) !important;
        width:    -moz-calc(100% - 2.4rem) !important;
        width:         calc(100% - 2.4rem) !important;
        margin-right: 1.2rem; 
        margin-left: 1.2rem;
    }
    .content.app-content .content-area-wrapper
    {
        margin-right: 1.2rem !important; 
        margin-left: 1.2rem !important;
    }
}

@media (max-width: 992px)
{
    .navbar-container #navbar-mobile .nav-back
    {
        margin-top: 5px;
    }
}

.card-group,
.card-deck
{
    margin-bottom: .75rem;
}

.card
{
    margin-bottom: 2.2rem;

    -webkit-transition: all .3s ease-in-out;
       -moz-transition: all .3s ease-in-out;
         -o-transition: all .3s ease-in-out;
            transition: all .3s ease-in-out; 

    border: none;
    border-radius: .5rem;
    -webkit-box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 4px 25px 0 rgba(0, 0, 0, .1);
}
.card .card
{
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
}
.card .card-subtitle
{
    margin-top: .5rem;
}
.card .card-title
{
    font-size: 1.32rem;
    font-weight: 500;

    margin-bottom: .5rem; 

    letter-spacing: .05rem;
}
.card .card-bordered
{
    border: 1px solid rgba(34, 41, 47, .125);
}
.card .card-img-overlay
{
    border-radius: .5rem;
}
.card.card-fullscreen
{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    display: block;
    overflow: auto; 

    width: 100% !important;
    height: 100% !important;
}
.card .card-body[class*='border-bottom-']
{
    border-bottom-width: 2px !important;
}
.card .card-img-overlay.bg-overlay
{
    background: rgba(0, 0, 0, .45);
}
.card .card-img-overlay .text-muted
{
    color: #1e1e1e !important;
}
.card.card-minimal
{
    border: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.card .card-header
{
    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding: 1.5rem 1.5rem 0;

    border-bottom: none;
    background-color: transparent; 

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
       -moz-box-pack: justify;
    -ms-flex-pack: justify;
            justify-content: space-between;
}
.card .card-header .card-title
{
    margin-bottom: 0;
}
.card .card-header .heading-elements,
.card .card-header .heading-elements-toggle
{
    position: absolute;
    top: 17px;
    right: 21px;

    cursor: pointer; 

    background-color: inherit;
}
.card .card-header .heading-elements.heading-top-elements .page-item,
.card .card-header .heading-elements-toggle.heading-top-elements .page-item
{
    display: inline-block;
}
.card .card-header .heading-elements a,
.card .card-header .heading-elements-toggle a
{
    padding: 0;
    padding-left: 8px;
}
.card .card-header .heading-elements a.btn,
.card .card-header .heading-elements-toggle a.btn
{
    padding-top: 6px;
    padding-bottom: 6px;
}
.card .card-header .heading-elements a[data-action='collapse'] i,
.card .card-header .heading-elements-toggle a[data-action='collapse'] i
{
    display: inline-block; 

    -webkit-transition: all .25s ease-out;
       -moz-transition: all .25s ease-out;
         -o-transition: all .25s ease-out;
            transition: all .25s ease-out;
}
.card .card-header .heading-elements a[data-action='collapse'].rotate i,
.card .card-header .heading-elements-toggle a[data-action='collapse'].rotate i
{
    -webkit-transform: rotate(-180deg);
       -moz-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
         -o-transform: rotate(-180deg);
            transform: rotate(-180deg);
}
.card .card-footer
{
    border-top: 1px solid #dae1e7;
}

.card-columns .card
{
    margin-bottom: 2.2rem;
}

.text-white .card-text
{
    color: white;
}

.text-white .card-img-overlay .text-muted
{
    color: #fff !important;
}

.text-white code
{
    background-color: white;
}

.text-white .heading-elements i
{
    color: #fff;
}

.card-head-inverse
{
    color: #fff;
}
.card-head-inverse .heading-elements i
{
    color: #fff;
}

.card-transparent
{
    background-color: transparent;
}

@media (max-width: 991.98px)
{
    .heading-elements
    {
        text-align: center;
    }
    .heading-elements .list-inline
    {
        display: none;
    }
    .heading-elements.visible
    {
        position: absolute;
        z-index: 997;
        top: 22px !important;
        top: 100%;
        top: 50px;
        left: 0;

        display: block;

        width: 100%; 
        height: auto;
        margin-top: 0;
        padding: 10px;
    }
    .heading-elements.visible .list-inline
    {
        position: absolute;
        top: 12px;
        right: 15px; 

        display: block;

        background-color: #fff;
    }
}

@media (min-width: 992px)
{
    .heading-elements-toggle
    {
        display: none;
    }
}

.overlay-img-card .card-img-overlay,
.overlay-img-card img
{
    max-height: 34.64rem;
}

.activity-timeline.timeline-left
{
    margin-left: 1.5rem; 
    padding-left: 40px;

    border-left: 2px solid #dae1e7;
}
.activity-timeline.timeline-left li
{
    position: relative;

    margin-bottom: 20px;
}
.activity-timeline.timeline-left li p
{
    margin-bottom: 0;
}
.activity-timeline.timeline-left li .timeline-icon
{
    position: absolute;
    top: 0;
    left: -4.3rem;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;

    padding: .7rem;

    color: #fff; 
    border-radius: 50%;

    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
    -ms-flex-align: center;
            align-items: center;
}
.activity-timeline.timeline-left li .timeline-icon i
{
    vertical-align: sub;
}

.activity-timeline.timeline-right
{
    margin-right: 1.5rem; 
    padding-right: 40px;

    border-right: 2px solid #dae1e7;
}
.activity-timeline.timeline-right li
{
    position: relative;

    margin-bottom: 20px;

    text-align: right;
}
.activity-timeline.timeline-right li p
{
    margin-bottom: 0;
}
.activity-timeline.timeline-right li .timeline-icon
{
    position: absolute;
    top: 0;
    right: -4.3rem;

    padding: .6rem .7rem;

    color: #fff; 
    border-radius: 50%;
}
.activity-timeline.timeline-right li .timeline-icon i
{
    vertical-align: sub;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .card-body,
    .card-content
    {
        min-height: 1px;
    }
    .profile-card-2 .card .card-header
    {
        display: block;
    }
    .profile-card-1 .card .avatar,
    .profile-card-3 .card .avatar
    {
        margin-right: auto; 
        margin-left: auto;
    }
}

.breadcrumb
{
    font-family: 'Montserrat', Helvetica, Arial, serif;
    font-size: 1rem;

    padding: .5rem 0 .5rem 1rem !important;

    border-left: 1px solid #d6dce1;
    border-radius: 0;
    background-color: transparent;
    /* .breadcrumb-item:first-child{
        a{
            &:after{
                content: "\e965";
                font-family: 'feather';
            }
        }
    } */
}
.breadcrumb > li + li::before
{
    padding-right: .6rem;
    padding-left: .6rem;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item
{
    padding-left: 0;
}
.breadcrumb .breadcrumb-item + .breadcrumb-item:before
{
    font-family: 'feather';

    content: '\e847';

    color: #626262;
}
.breadcrumb .breadcrumb-item.active
{
    color: #626262;
}

/* Custom scss for page headers style */
.breadcrumbs-right .breadcrumb
{
    float: right;
}

.breadcrumbs-top .breadcrumb
{
    margin: 0;
    padding: 0;
}

/* Component Specific */
#component-breadcrumbs .breadcrumb
{
    border: none;
}
#component-breadcrumbs .breadcrumb .breadcrumb-item + .breadcrumb-item:before
{
    font-family: none;

    padding: 0 1rem;
}

#component-breadcrumbs .default-breadcrumb.breadcrumb .breadcrumb-item + .breadcrumb-item:before
{
    content: '/';
}

#component-breadcrumbs .breadcrumb-dots.breadcrumb .breadcrumb-item + .breadcrumb-item:before
{
    position: relative;
    top: -4px; 

    content: '.';
}

#component-breadcrumbs .breadcrumb-dashes.breadcrumb .breadcrumb-item + .breadcrumb-item:before
{
    content: '-';
}

#component-breadcrumbs .breadcrumb-pipes.breadcrumb .breadcrumb-item + .breadcrumb-item:before
{
    content: '|';
}

#component-breadcrumbs .breadcrumb-chevron.breadcrumb .breadcrumb-item + .breadcrumb-item:before
{
    font-family: 'feather';

    position: relative;
    top: 1px; 

    content: '\e844';
}

#breadcrumb-alignment .breadcrumb-wrapper
{
    padding: 1px;

    border-radius: 10px; 
    -webkit-box-shadow: 1px 1px 10px rgba(0, 0, 0, .1);
            box-shadow: 1px 1px 10px rgba(0, 0, 0, .1);
}
#breadcrumb-alignment .breadcrumb-wrapper .breadcrumb
{
    margin-top: .8rem;
}

.badge
{
    font-weight: 400; 

    color: #fff;
    background-color: #46003A;
}
.badge[class*='badge-'] [class*='icon-']
{
    line-height: 1;
}
.badge[class*='badge-'] a
{
    color: #fff;
}
.badge[class*='badge-'] i
{
    vertical-align: middle;
}
.badge[class*='badge-'] .dropdown-toggle span,
.badge[class*='badge-'] .dropdown-toggle i,
.badge[class*='badge-'].dropdown-toggle span,
.badge[class*='badge-'].dropdown-toggle i
{
    vertical-align: text-top;
}
.badge[class*='badge-'] .dropdown-toggle i,
.badge[class*='badge-'].dropdown-toggle i
{
    padding-left: .2rem;
}
.badge[class*='badge-'] .dropdown-toggle::after,
.badge[class*='badge-'].dropdown-toggle::after
{
    font-size: 1rem; 

    position: relative;
    top: 0;
    left: 0;
}
.badge[class*='badge-'] .dropdown-menu a
{
    color: #626262;
}
.badge.badge-square
{
    border-radius: 0;
}
.badge.badge-up
{
    position: absolute;
    top: -1rem;
    right: -1rem;
}
.badge.badge-up.badge-sm
{
    top: -.5rem;
    right: -.5rem;
}

.badge-xl
{
    font-size: 1.8rem;
}

.badge-lg
{
    font-size: 1.2rem;
}

.badge-md
{
    font-size: 1rem;
}

.badge-sm
{
    font-size: .7rem;
}

.badge-icon i
{
    font-size: 100%;

    margin-right: 5px;
}

.dropup .badge
{
    cursor: pointer;
}

.nav
{
    border-radius: .25rem;
}
.nav.wrap-border
{
    border: 1px solid #ddd;
}
.nav.wrap-border li.nav-header
{
    margin: 0 .5rem;
}
.nav.wrap-border li.nav-item,
.nav.wrap-border div
{
    padding: 2px .714rem;
}
.nav.square-border
{
    border-radius: 0;
}
.nav.square-border .nav-item .nav-link.active
{
    border-radius: 0;
}
.nav .modern-nav-toggle
{
    padding: 1.571rem 0;
}
.nav.nav-pills
{
    margin-bottom: 1rem;
}
.nav.nav-pills .nav-item .nav-link
{
    font-size: .98rem;

    margin-right: .5rem;
    padding: .2rem 1rem;
    padding-top: .3rem;

    color: #000; 
    border-radius: .357rem;
}
.nav.nav-pills .nav-item .nav-link.active
{
    color: #fff;
}
.nav.nav-pills .nav-item .nav-link.disabled
{
    color: #b8c2cc;
}
.nav.nav-pills .nav-item .nav-link.dropdown-toggle::after
{
    top: 1px;
    left: 1px;
}
.nav.nav-pills .nav-item.dropdown.show .nav-link
{
    color: #fff;
}
.nav.nav-pills .nav-item.dropdown.show .dropdown-item.active:hover
{
    color: #46003A;
}
.nav.nav-pills.nav-justified
{
    width: 100%;
}
.nav.nav-pills.nav-justified .nav-item
{
    float: none;
}
.nav.nav-pills.nav-justified .nav-link
{
    margin-bottom: 5px; 

    text-align: center;
}
.nav.nav-pills.nav-justified > .dropdown .dropdown-menu
{
    top: auto;
    left: auto;
}
@media (min-width: 576px)
{
    .nav.nav-pills.nav-justified .nav-item
    {
        display: block;

        width: 1%;
    }
    .nav.nav-pills.nav-justified .nav-link
    {
        margin-bottom: 0;
    }
}
.nav.nav-pills.nav-justified .nav-link
{
    margin-right: 0;

    border-radius: .5rem;
}
@media (min-width: 576px)
{
    .nav.nav-pills.nav-justified .nav-link.active,
    .nav.nav-pills.nav-justified .nav-link.active:hover,
    .nav.nav-pills.nav-justified .nav-link.active:focus
    {
        border-bottom-color: transparent;
    }
}
.nav.nav-pills.nav-justified .nav-link
{
    display: block;
}
.nav.nav-pills.nav-justified .nav-link.active
{
    border: none;
}
.nav.nav-pills.nav-justified .nav-link.active:hover,
.nav.nav-pills.nav-justified .nav-link.active:focus
{
    border: none;
}
.nav.nav-pills.nav-active-bordered-pill .nav-item a.nav-link.active
{
    color: #46003A;
    border: 1px solid #46003A;
    border-radius: .25rem;
    background-color: #fff;
}
.nav.nav-tabs
{
    position: relative;

    margin-bottom: 1rem;

    border: none;
    border-radius: 0; 
    -webkit-box-shadow: -1px 11px 17px -10px rgba(0, 0, 0, .04);
            box-shadow: -1px 11px 17px -10px rgba(0, 0, 0, .04);
}
.nav.nav-tabs .nav-item
{
    position: relative;
}
.nav.nav-tabs .nav-item .nav-link
{
    font-size: .95rem;
    font-weight: 450;

    min-width: auto;
    padding: .61rem .635rem;

    color: #000;
    border: none;
    border-radius: 0;
}
.nav.nav-tabs .nav-item .nav-link.active
{
    position: relative;

    -webkit-transition: all .2s ease;
       -moz-transition: all .2s ease;
         -o-transition: all .2s ease;
            transition: all .2s ease;
    -webkit-transform: translateY(-2px);
       -moz-transform: translateY(-2px);
        -ms-transform: translateY(-2px);
         -o-transform: translateY(-2px);
            transform: translateY(-2px); 

    color: #46003A;
    border: none;
    background-color: #fff;
}
.nav.nav-tabs .nav-item .nav-link.active:after
{
    position: absolute;
    top: 100%;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 2px;

    content: attr(data-before);
    -webkit-transition: all .2s linear;
       -moz-transition: all .2s linear;
         -o-transition: all .2s linear;
            transition: all .2s linear; 
    -webkit-transform: translateY(0px);
       -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
         -o-transform: translateY(0px);
            transform: translateY(0px);

    background: -webkit-linear-gradient(60deg, #46003A, rgba(115, 103, 240, .5)) !important;
    background:    -moz-linear-gradient(60deg, #46003A, rgba(115, 103, 240, .5)) !important;
    background:      -o-linear-gradient(60deg, #46003A, rgba(115, 103, 240, .5)) !important;
    background:         linear-gradient(30deg, #46003A, rgba(115, 103, 240, .5)) !important;
    -webkit-box-shadow: 0 0 8px 0 rgba(115, 103, 240, .5) !important;
            box-shadow: 0 0 8px 0 rgba(115, 103, 240, .5) !important;
}
.nav.nav-tabs .nav-item .nav-link.dropdown-toggle::after
{
    top: 1px;
    left: 1px;
}
.nav.nav-tabs .nav-item .nav-link.disabled
{
    color: #b8c2cc;
}
.nav.nav-tabs.nav-justified
{
    width: 100%;
}
.nav.nav-tabs.nav-justified .nav-item
{
    float: none;
}
.nav.nav-tabs.nav-justified .nav-link
{
    margin-bottom: 5px; 

    text-align: center;
}
.nav.nav-tabs.nav-justified > .dropdown .dropdown-menu
{
    top: auto;
    left: auto;
}
@media (min-width: 576px)
{
    .nav.nav-tabs.nav-justified .nav-item
    {
        display: block;

        width: 1%;
    }
    .nav.nav-tabs.nav-justified .nav-link
    {
        margin-bottom: 0;
    }
}
.nav.nav-tabs.nav-justified .nav-link
{
    margin-right: 0;

    border-radius: .5rem;
}
@media (min-width: 576px)
{
    .nav.nav-tabs.nav-justified .nav-link.active,
    .nav.nav-tabs.nav-justified .nav-link.active:hover,
    .nav.nav-tabs.nav-justified .nav-link.active:focus
    {
        border-bottom-color: transparent;
    }
}
.nav.nav-tabs.nav-justified .nav-item a.nav-link
{
    display: block;

    border-radius: 0;
}
.nav.nav-tabs.nav-justified .nav-item a.nav-link.active
{
    border: none;
    background-color: #fff;
}
.nav.nav-tabs.nav-justified .nav-item a.nav-link:hover
{
    border-color: transparent;
}

.nav-vertical
{
    overflow: hidden;
}
.nav-vertical .nav.nav-tabs .nav-item .nav-link
{
    margin-bottom: 0; 

    border: none;
}
.nav-vertical .nav.nav-tabs .nav-item .nav-link.active
{
    border: none;
    border-radius: 0;
}
.nav-vertical .nav.nav-tabs .nav-item .nav-link.active:after
{
    top: 1rem;
    bottom: 100%;
    left: -1rem;

    width: 2.14rem; 

    -webkit-transform: rotate(90deg);
       -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
         -o-transform: rotate(90deg);
            transform: rotate(90deg);
}
.nav-vertical .nav.nav-tabs .nav-item .nav-link:hover
{
    border-right: none;
}
.nav-vertical .nav.nav-tabs.nav-left
{
    display: table;
    float: left;

    margin-right: 1rem;
}
.nav-vertical .nav.nav-tabs.nav-left ~ .tab-content .tab-pane
{
    display: none;
    overflow-y: auto;

    padding-left: 1rem; 

    background-color: #fff;
}
.nav-vertical .nav.nav-tabs.nav-left ~ .tab-content .tab-pane.active
{
    display: block;
}
.nav-vertical .nav.nav-tabs.nav-right
{
    display: table;
    float: right;

    margin-left: 1rem;
}
.nav-vertical .nav.nav-tabs.nav-right .nav-item .nav-link.active:after
{
    left: 2.4rem;
}
.nav-vertical .nav.nav-tabs.nav-right ~ .tab-content .tab-pane
{
    display: none;
    overflow-y: auto;

    padding-right: 1rem; 

    background-color: #fff;
}
.nav-vertical .nav.nav-tabs.nav-right ~ .tab-content .tab-pane.active
{
    display: block;
}

.alert
{
    font-weight: 500;

    border: none;
}
.alert .close:focus
{
    outline: 0;
}
.alert p
{
    font-weight: 500;

    margin-bottom: 0;
    padding: 2px 0;

    vertical-align: middle;
}
.alert span
{
    vertical-align: middle;
}
.alert .alert-heading
{
    font-size: .9rem;
    font-weight: 700;

    padding: 0;
    padding-bottom: 8px;
}
.alert.alert-dark .alert-heading
{
    -webkit-box-shadow: rgba(30, 30, 30, .4) 0 6px 15px -7px;
            box-shadow: rgba(30, 30, 30, .4) 0 6px 15px -7px;
}
.alert.alert-light .alert-heading
{
    -webkit-box-shadow: rgba(186, 191, 199, .4) 0 6px 15px -7px;
            box-shadow: rgba(186, 191, 199, .4) 0 6px 15px -7px;
}

.media-list .media
{
    width: 100%;
    margin-top: 0; 
    padding: 1.25rem;
}
.media-list .media .media-left
{
    padding-right: 1rem;
}
.media-list .media .media-right
{
    padding-left: 1rem;
}

.media-list a.media
{
    color: #b8c2cc !important;
}

.media-bordered .media
{
    border-top: 1px solid #e4e7ed;
}
.media-bordered .media:first-child
{
    border-top: 0;
}

/*=========================================================================================
  File Name: progress.scss
  Description: Extended bootstrap progress bar scss.
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax HTML Admin Template
  Version: 1.1
  Author: PIXINVENT
  Author URL: http://www.themeforest.net/user/pixinvent
  ----------------------------------------------------------------------------------------

  Modifiers:       `progress-lg`
                   `progress-md`
                   `progress-sm`
                   `progress-xs`

==========================================================================================*/
.progress
{
    margin-bottom: 3rem;
}
.progress:last-child
{
    margin-bottom: 1rem;
}
.progress.progress-xl
{
    height: 1.14rem;
}
.progress.progress-lg
{
    height: .857rem;
}
.progress.progress-md
{
    height: .57rem;
}
.progress.progress-sm
{
    height: .143rem;
}
.progress .progress-bar
{
    border-radius: 1.28rem;
}

/* ===============================================================================================
	File Name: list-group.scss
	Description: Contain list item, list group related extended SCSS.
	----------------------------------------------------------------------------------------------
	Item Name: Vuesax HTML Admin Template
	Version: 1.1
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================================*/
ul.list-inline li
{
    display: inline-block;
}

ul.list-inline.list-inline-pipe > li + li:before
{
    padding-right: 2px; 

    content: ' | ';
}

.list-group .list-group-item
{
    line-height: 1.5;
}
.list-group .list-group-item.active:hover
{
    background-color: #46003A;
}
.list-group .list-group-item.active:focus
{
    color: #626262;
    outline: 0;
}
.list-group .list-group-item.active:focus:hover
{
    color: #fff;
}
.list-group .list-group-item:hover
{
    background-color: #f8f8f8;
}
.list-group .list-group-item i
{
    font-size: 1.2rem;
    font-weight: 100;

    position: relative;
    top: 2px;
}
.list-group .list-group-item:not(.active):focus:active
{
    color: inherit;
}

.list-group .list-group-item-action:active
{
    color: #fff; 
    background-color: #46003A;
}

.list-group .list-group-item-action:focus
{
    outline: 0; 
    background-color: #f8f8f8;
}

.list-group-item.gu-mirror
{
    border-radius: 0;
}

.users-list li + li
{
    margin-left: -.785rem;
}

.users-list li img
{
    border: 2px solid #fff;
    -webkit-box-shadow: 0 2px 10px 0 rgba(184, 194, 204, .3);
            box-shadow: 0 2px 10px 0 rgba(184, 194, 204, .3);
}

.users-list li .badge
{
    color: #22292f;
}

.toast-bs-container
{
    position: fixed;
    z-index: 1040;
    top: 0;
    left: 0;

    width: 100%;
}
.toast-bs-container .toast-position
{
    position: relative;
    top: 0;
    right: 0;

    display: -webkit-box;
    display: -webkit-flex;
    display:    -moz-box;
    display: -ms-flexbox;
    display:         flex;
            flex-direction: column;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
       -moz-box-orient: vertical;
       -moz-box-direction: normal;
        -ms-flex-direction: column;
    -webkit-flex-wrap: wrap-reverse;
        -ms-flex-wrap: wrap-reverse;
            flex-wrap: wrap-reverse;
}
.toast-bs-container .toast-position .toast.show
{
    z-index: 1030; 

    opacity: 1;
}

.toast-placement
{
    position: static;
    top: 0;
}
.toast-placement .toast
{
    position: fixed;
    z-index: 1030; 
    top: 0;
}

.toast
{
    display: none;

    margin-top: .75rem; 

    background-color: white;
}
.toast .toast-header .close:focus,
.toast .toast-header .close:active
{
    outline: none;
}

.toast.show.toast-wrapper
{
    position: static;
}

.toast.toast-translucent
{
    display: block; 

    opacity: 1;
}

.toast-header img
{
    width: 35px;
    height: 24px;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
    .toast-bs-container
    {
        position: fixed !important;
        left: 74% !important;
    }
}

_:-ms-lang(x),
.toast-bs-container
{
    position: fixed !important;
    left: 74% !important;
}

.accordion .collapse-title,
.collapse-bordered .collapse-title
{
    font-size: 1.2rem; 
    font-weight: 400;
}

.accordion .card-header,
.collapse-bordered .card-header
{
    cursor: pointer;
}

.collapse-title
{
    color: inherit;
}
.collapse-title:hover
{
    color: inherit;
}

.collapse-bordered .card:first-child
{
    border-top: 0;
}

.collapse-bordered .card:last-child
{
    border-bottom: 0;
}

.collapse-bordered .card
{
    margin-bottom: 0;

    border-bottom: 1px solid rgba(0, 0, 0, .04);
    border-radius: 0;
}
.collapse-bordered .card .card-header
{
    padding: 1rem;
}
.collapse-bordered .card .card-body
{
    line-height: 1.5; 

    padding: 1rem;
}

.accordion .collapse-border-item.card
{
    margin-bottom: 0; 

    border: 1px solid rgba(0, 0, 0, .1);
}
.accordion .collapse-border-item.card:first-child
{
    border-bottom: 0;
}
.accordion .collapse-border-item.card .card-header
{
    padding: 1rem;
}
.accordion .collapse-border-item.card .card-body
{
    line-height: 1.5; 

    padding: 1rem;
}

.accordion-shadow
{
    padding: .15rem .75rem;

    border-radius: .5rem; 
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, .1);
}
.accordion-shadow .card.open
{
    margin: 10px 0;

    border: 0;
    border-radius: .571rem;
    -webkit-box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1) !important;
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1) !important;
}
.accordion-shadow .card:first-child
{
    border-top-left-radius: .571rem;
    border-top-right-radius: .571rem;
}
.accordion-shadow .card:last-child
{
    border-bottom-right-radius: .571rem; 
    border-bottom-left-radius: .571rem;
}

.collapse-border
{
    border: 1px solid rgba(0, 0, 0, .1);
    border-radius: .5rem;
}
.collapse-border + .collapse.show
{
    border: 1px solid rgba(0, 0, 0, .1);
}

.collapse-margin
{
    margin-top: .71rem;
    margin-bottom: .71rem !important;

    border-bottom: 0 solid transparent !important; 
    border-radius: 5px;
    -webkit-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .05);
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, .05);
}
.collapse-margin .card-header
{
    padding: 1rem;

    border-radius: 5px;
}
.collapse-margin .card-body
{
    line-height: 1.5rem; 

    padding: 1rem;
}

.pagination .page-item
{
    border-radius: 5rem;
}
.pagination .page-item.prev-item .page-link,
.pagination .page-item.next-item .page-link,
.pagination .page-item.first .page-link,
.pagination .page-item.last .page-link,
.pagination .page-item.prev .page-link,
.pagination .page-item.next .page-link
{
    color: #46003A;
}
.pagination .page-item.prev-item.disabled .page-link,
.pagination .page-item.next-item.disabled .page-link,
.pagination .page-item.first.disabled .page-link,
.pagination .page-item.last.disabled .page-link,
.pagination .page-item.prev.disabled .page-link,
.pagination .page-item.next.disabled .page-link
{
    color: #b8c2cc;
}
.pagination .page-item.prev-item
{
    margin-right: .3571rem;
}
.pagination .page-item.prev-item .page-link
{
    padding: .572rem .651rem;

    border-radius: 50%; 
    background-color: #f0f0f0;
}
.pagination .page-item.prev-item .page-link i
{
    font-size: 1.2rem;

    position: relative;
    top: 1px;
}
.pagination .page-item.prev-item .page-link:hover
{
    color: #fff; 
    background: #46003A;
}
.pagination .page-item.prev-item ~ .page-item:nth-child(2).active
{
    border-top-left-radius: 1.428rem;
    border-bottom-left-radius: 1.428rem;
}
.pagination .page-item.prev-item ~ .page-item:nth-child(2).active .page-link
{
    border-top-left-radius: 5rem !important;
    border-bottom-left-radius: 5rem !important;
}
.pagination .page-item.prev-item ~ .page-item:nth-last-child(-n+2).active
{
    border-top-right-radius: 1.428rem;
    border-bottom-right-radius: 1.428rem;
}
.pagination .page-item.prev-item ~ .page-item:nth-last-child(-n+2).active .page-link
{
    border-top-right-radius: 5rem !important;
    border-bottom-right-radius: 5rem !important;
}
.pagination .page-item.next-item
{
    margin-left: .3571rem;
}
.pagination .page-item.next-item .page-link
{
    padding: .572rem .651rem;

    border-radius: 50%; 
    background-color: #f0f0f0;
}
.pagination .page-item.next-item .page-link i
{
    font-size: 1.2rem;

    position: relative;
    top: 1px;
}
.pagination .page-item.next-item .page-link:hover
{
    color: #fff; 
    background: #46003A;
}
.pagination .page-item .page-link
{
    font-size: 1rem;
    font-weight: 700; 

    margin: 0;
    margin-left: 0;

    color: rgba(0, 0, 0, .6);
    border: none;
    background-color: #f0f0f0;
}
.pagination .page-item .page-link i
{
    position: relative;
    top: 1px;
}
.pagination .page-item .page-link:focus
{
    -webkit-box-shadow: none;
            box-shadow: none;
}
.pagination .page-item .page-link:hover
{
    color: #46003A;
}
.pagination .page-item.active
{
    border-radius: 0; 
    background-color: #f0f0f0;
}
.pagination .page-item.active .page-link
{
    z-index: 3;

    -webkit-transform: scale(1.05);
       -moz-transform: scale(1.05);
        -ms-transform: scale(1.05);
         -o-transform: scale(1.05);
            transform: scale(1.05); 

    color: #fff;
    border-radius: 5rem;
    background-color: #46003A;
}
.pagination .page-item:first-child:not(.prev-item).active
{
    border-top-left-radius: 1.428rem;
    border-bottom-left-radius: 1.428rem;
}
.pagination .page-item:first-child:not(.prev-item).active .page-link
{
    border-radius: 5rem;
}
.pagination .page-item:first-child:not(.prev-item) .page-link
{
    border-top-left-radius: 1.428rem;
    border-bottom-left-radius: 1.428rem;
}
.pagination .page-item:last-child:not(.next-item).active
{
    border-top-right-radius: 1.428rem;
    border-bottom-right-radius: 1.428rem;
}
.pagination .page-item:last-child:not(.next-item).active .page-link
{
    border-radius: 5rem;
}
.pagination .page-item:last-child:not(.next-item) .page-link
{
    border-top-right-radius: 1.428rem;
    border-bottom-right-radius: 1.428rem;
}

.pagination.pagination-lg .page-item .page-link
{
    font-size: 1.5rem;

    -webkit-transform: scale(1);
       -moz-transform: scale(1);
        -ms-transform: scale(1);
         -o-transform: scale(1);
            transform: scale(1);
}

.modal .modal-header
{
    padding: .8rem;

    border-bottom: none; 
    border-radius: .42rem;
    background-color: #f8f8f8;
}
.modal .modal-header .close
{
    position: relative;

    padding: .2rem .62rem;

    -webkit-transition: all .23s ease .1s;
       -moz-transition: all .23s ease .1s;
         -o-transition: all .23s ease .1s;
            transition: all .23s ease .1s;
    -webkit-transform: translate(8px, -2px);
       -moz-transform: translate(8px, -2px);
        -ms-transform: translate(8px, -2px);
         -o-transform: translate(8px, -2px);
            transform: translate(8px, -2px); 

    opacity: 1;
    border-radius: .357rem;
    background: #fff;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
}
.modal .modal-header .close span
{
    font-size: 2rem;
    font-weight: 400;

    color: #46003A;
}
.modal .modal-header .close:hover,
.modal .modal-header .close:focus,
.modal .modal-header .close:active
{
    -webkit-transform: translate(5px, 3px);
       -moz-transform: translate(5px, 3px);
        -ms-transform: translate(5px, 3px);
         -o-transform: translate(5px, 3px);
            transform: translate(5px, 3px);

    opacity: 1;
    outline: none;
    -webkit-box-shadow: none;
            box-shadow: none;
}
.modal .modal-header[class*='bg-']
{
    color: #fff;
    border-bottom-right-radius: 5px; 
    border-bottom-left-radius: 5px;
}
.modal .modal-header[class*='bg-'] .modal-title
{
    color: #fff;
}
.modal .modal-header.bg-primary .close span
{
    color: #46003A;
}
.modal .modal-header.bg-success .close span
{
    color: #28c76f;
}
.modal .modal-header.bg-info .close span
{
    color: #00cfe8;
}
.modal .modal-header.bg-danger .close span
{
    color: #ea5455;
}
.modal .modal-header.bg-warning .close span
{
    color: #ff9f43;
}
.modal .modal-header.bg-dark .close span
{
    color: #1e1e1e;
}

.modal .modal-content
{
    overflow: visible;

    border: none;
    -webkit-box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
            box-shadow: 0 5px 20px 0 rgba(0, 0, 0, .1);
}

.modal .modal-footer
{
    border-color: rgba(0, 0, 0, .05);
}

.modal-sm
{
    max-width: 400px;
}

.modal-xs
{
    max-width: 300px;
}

.modal-xl
{
    max-width: 94%;
    margin-right: 3%; 
    margin-left: 3%;
}

@media (max-width: 576px)
{
    .modal
    {
        padding-right: 1rem;
        padding-left: 1rem;
    }
    .modal .modal-xs,
    .modal .modal-sm
    {
        max-width: unset;
    }
}

.popover
{
    z-index: 10;
}
.popover .popover-header
{
    color: #fff;
    border: none;
    border-top-left-radius: .3rem;
    border-top-right-radius: .3rem;
}
.popover.bs-popover-bottom .arrow:after
{
    border-bottom-color: #46003A;
}

.icon-bg-circle
{
    padding: .5rem;

    color: #fff;
    border-radius: 50%;
}

.icon-left
{
    margin-right: .5rem;
}

.icon-right
{
    margin-right: .5rem;
}

.icon-spin
{
    display: inline-block;

    -webkit-animation: spin 1s infinite linear;
       -moz-animation: spin 1s infinite linear;
         -o-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
}

.blockOverlay
{
    z-index: 1050 !important;
}

.blockElement,
.blockPage
{
    z-index: 1051 !important;
}

@-moz-keyframes spin
{
    from
    {
        -moz-transform: rotate(0deg);
    }
    to
    {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin
{
    from
    {
        -webkit-transform: rotate(0deg);
    }
    to
    {
        -webkit-transform: rotate(360deg);
    }
}

@-o-keyframes spin
{
    from
    {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }
    to
    {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes spin
{
    from
    {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to
    {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.hidden
{
    display: none;
    visibility: hidden;
}

a:hover
{
    cursor: pointer;
}

.round
{
    border-radius: 1.5rem;
}

.square
{
    border-radius: 0;
}

.icon-line-height
{
    line-height: 1.5rem !important;
}

.table-middle td
{
    vertical-align: middle;
}

.spinner
{
    display: inline-block;

    -webkit-animation: spin 1s linear infinite;
       -moz-animation: spin 1s linear infinite;
         -o-animation: spin 1s linear infinite;
            animation: spin 1s linear infinite;
}

@keyframes spin
{
    0%
    {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%
    {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
